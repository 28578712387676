import React, { useState, useEffect, useContext } from "react"


import { CSVLink } from "react-csv";
import $ from 'jquery'
import { Row, Col, Form, Button, InputGroup, FormControl } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"

import { Context } from '../../services/Context'
import { handleError } from "../../helpers/ErrorHelpers"
import IsActiveFilter from "../../templates/IsActiveFilter"
import MainStructure from "../../structures/MainStructure"
import CompaniesFilter from "../../templates/CompaniesFilter"
import datatablePt from "../../../assets/lang/datatablePt.json"
import Loader from '../../templates/Loader'

$.DataTable = require("datatables.net")

export default function PersonsList() {

    const { getAxios } = useContext(Context)
    const axios = getAxios()
    const history = useHistory()
    const [company, setCompany] = useState(false)
    const [isActive, setIsActive] = useState(true)
    const [persons, setPersons] = useState([])
    const [csvData, setCsvData] = useState(false);

    useEffect(() => {
        loadPersons()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company, isActive])

    useEffect(() => {
        loadDataTable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [persons])

    async function loadPersons() {
        let localPersons = await getPersons()
        if (localPersons) setPersons(localPersons)

    }

    function createCsvData(data){
        let csvData = []
        data.map((person) =>
        csvData.push({
            'company_id':company.external_id,
            'name':  person.name,
            'external_id': person.external_id
        })
        );
        return csvData
    }

    async function getPersons() {
        if (!company) return
        let persons = false
        let personsCsv =  []
        try {
            console.log(company)
            let response = await axios.get(`/persons/company/${company['_id']}/storage`)
            if (response && response.data.success) {
                persons = response.data.persons
                persons = filterPersons(persons)
                if(persons && persons.length >=0){
                    personsCsv = createCsvData(persons)
                    setCsvData(personsCsv)
                }
            } else {
                persons = []
            }
        } catch (error) {
            handleError(error, "PersonsList", "getAndSetUserPersons")
        }
        return persons
    }

    function filterPersons(persons) {
        persons = persons.filter((person) => person.is_active === isActive)
        return persons
    }

    function clearDataTable() {
        $('#persons-table').DataTable().clear()
        $('#persons-table').DataTable().destroy()
    }

    function loadDataTable() {
        if ($('#persons-table').DataTable()) {
            clearDataTable()
        }

        let data = []
        if (persons && persons.length > 0) {
            persons.forEach(item => {
                data.push([item.name, item._id])
            })
            var table = $('#persons-table').DataTable({
                dom: 'Bitp',
                "language": datatablePt,
                data: data,
                "columnDefs": [
                    {
                        "data": null,
                        "render": function (data, type, row, meta) {
                            let personId = row[1]

                            return `<div class="text-center" style="font-size: 10pt;">
                                <i id="view-btn-`+ personId + `" name="view-btn" class="m-1 h4 text-gryfo-blue cursor-pointer fas fa-user" data-toggle="tooltip" data-placement="left" title="Ver Pessoa"></i>
                                <i id="edit-btn-`+ personId + `" name="edit-btn" class="m-1 h4 text-gryfo-dark-yellow cursor-pointer fas fa-edit" data-toggle="tooltip" data-placement="left" title="Editar Pessoa"></i>
                                <i id="rm-btn-`+ personId + `" name="act-btn" class="m-1 h4 cursor-pointer fas ${!isActive ? "fa-handshake text-success" : "fa-ban"}" data-toggle="tooltip" data-placement="left" title="${!isActive ? "Reativar Pessoa" : "Desativar Pessoa"}"></i>
                                <i id="rm-btn-` + personId + `" name="rm-btn" class="m-1 h4 text-danger cursor-pointer fas fa-trash" data-toggle="tooltip" data-placement="left" title="Remover Pessoa"></i>
                            </div>`


                        },
                        "targets": -1
                    }
                ]
            })
            $('#search-person').on('keyup', function () {
                table.search($('#search-person').val()).draw()
            })

            $('body').on('click', "i[name='view-btn']", function () {
                var data = table.row($(this).parents('tr')).data()
                if (!data || data.length === 0) return
                history.push('/person/' + data[1])
            })

            $('body').on('click', "i[name='edit-btn']", function () {
                var data = table.row($(this).parents('tr')).data()
                if (!data || data.length === 0) return
                history.push('/person/update/' + data[1])
            })

            $('body').on('click', "i[name='act-btn']", async function () {
                var data = table.row($(this).parents('tr')).data()
                if (!data || data.length === 0) return

                let response = await axios.post('/person/update/' + data[1] + '/storage', { is_active: !isActive })
                if (response && response.data.success) {
                    loadPersons()
                }
            })

            $('body').on('click', "i[name='rm-btn']", async function () {
                var data = table.row($(this).parents('tr')).data();
                if (!data || data.length === 0) return

                if (!window.confirm("Deseja mesmo remover esta pessoa?")) return
                let response = await axios.delete('/person/delete/' + data[1] + '/storage')
                if (response && response.status === 200) {
                    response.data.success ? loadPersons() : alert(response.data.message)
                }
            });
        }

    }



    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">PESSOAS</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/person/add">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-plus mr-1" /> Cadastrar Pessoa
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                
                <Loader show={persons}>
                    <Form>
                        <Form.Row>
                            <CompaniesFilter 
                                onSelectCompany={setCompany} 
                                companyMode="client"
                                className="form-group my-2 col-12 col-md align-self-center"
                            />
                            <IsActiveFilter onChange={setIsActive} />
                            <InputGroup className="form-group my-2 col-12 col-md">
                                <InputGroup.Prepend>
                                    <InputGroup.Text ><i className="fas fa-search"></i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl id="search-person" placeholder="Procurar pessoa" />
                            </InputGroup>

                        </Form.Row>


                    </Form>
                    
                    {csvData &&
                    <CSVLink filename={"registered_persons.csv"} data={csvData}>{`Clique para baixar todos os cadastros de ${company.company_name}` }</CSVLink>}
                    {(persons && persons.length > 0) ?
                        <div className="table-responsive">
                            <table id="persons-table" className="table table-striped table-bordered" width="100%">
                                <thead className="bg-gryfo-blue text-white">
                                    <tr>
                                        <th>NOME</th>
                                        <th className="text-center" style={{ maxWidth: "300px" }}>AÇÕES</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        :
                        company ?
                            'Não há pessoas cadastradas nessa empresa'
                            :
                            'Selecione uma empresa para listar suas pessoas'
                    }
                </Loader>

            </div>
        </MainStructure>
    )
}
