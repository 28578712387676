import React, { useState, useEffect, useContext, Fragment } from "react"
import { Row, Col, Form, Button } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"
import { Highlighter, Token } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { Context } from '../../services/Context'
import { handleError } from "../../helpers/ErrorHelpers"
import MainStructure from "../../structures/MainStructure"
import CompaniesFilter from "../../templates/CompaniesFilter"
import Status from "../../templates/Status"

export default function AddGroup(){

    const { getAxios, getAuthenticatedUser } = useContext(Context)
    const axios = getAxios()
    const history = useHistory()
    
    const [userbusinessModel, setUserbusinessModel] = useState("")

    const [groupName, setGroupName] = useState("")
    const [groupExternalId, setGroupExternalId] = useState("")
    const [partner, setPartner] = useState(false)
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [status, setStatus] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function getAndSetUser() {
            const user = await getAuthenticatedUser()
            if (user.access_level !== 'admin' || (user.business_model !== 'partner' && user.business_model !== 'gryfo')) {
                history.replace("/")
                return
            }
            setUserbusinessModel(user.business_model)
        }
        getAndSetUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function checkFormIsValid() {
        if (!groupName) return false
        return true
    }

    async function handleSubmit(e) {
        e.preventDefault()
        if (!checkFormIsValid()) {
            return
        }
        let status = await postGroup()
        if (status.success) {
            status.message = "Grupo criado com sucesso!"
            setGroupName("")
            if(userbusinessModel === 'gryfo') setPartner(false)
            setGroupExternalId("")
            setSelectedCompanies([])
        }

        setStatus(status)
    }

    async function postGroup() {
        setIsLoading(true)
        let status = { success: false, message: "Não foi possível criar o Grupo" }
        let url = "/company_group/add"
        let companies_ids
        try {
            if(userbusinessModel === 'gryfo'){
                companies_ids = selectedCompanies.map((company) => { 
                    return company["_id"]
                })
            }else{
                companies_ids = selectedCompanies.map((company) => { 
                    return company["external_id"]
                })
            }
            
            let data = {
                name: groupName,
                companies_list:companies_ids
            }
            if (groupExternalId !== ''){
                data['external_id'] = groupExternalId
            }

            if (userbusinessModel === 'gryfo') {
                data['partner_id'] = partner['_id']
                url = "/company_group/add/storage"
            }

            let response = await axios.post(url, data)
            if (response) {
                status = response.data
            }
        } catch (error) {
            handleError(error, "AddCompany", "postGroup", groupName)
        }
        setIsLoading(false)
        return status
    }

    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">ADICIONAR UM GRUPO DE EMPRESAS</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/groups">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-arrow-left mr-1" /> Voltar
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Status status={status} />
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>Nome do Grupo</Form.Label>
                                <Form.Control type="text" value={groupName} onChange={e => setGroupName(e.target.value)} placeholder="Insira o nome do grupo de empresas" required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>ID Externo (opcional)</Form.Label>
                                <Form.Control type="text" value={groupExternalId} onChange={e => setGroupExternalId(e.target.value)} placeholder="Insira o ID do grupo, gerado automaticamente caso não informado"/>
                            </Form.Group>
                            <Form.Group hidden={userbusinessModel === 'partner'}>
                                <Form.Label>Empresa Parceira</Form.Label>
                                <CompaniesFilter 
                                    onSelectCompany={setPartner} 
                                    companyMode={'partner'}
                                    placeholder={'Parceiro'} 
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Empresas Inclusas</Form.Label>
                                <CompaniesFilter
                                        onSelectCompany={setSelectedCompanies}
                                        companyMode="client"
                                        multiple={true}
                                        partnerId={
                                            (partner) ?
                                            partner['_id']
                                            :
                                            null
                                        }
                                        placeholder="Cliente"
                                        disabled={!partner}
                                        required={true}
                                    />
                            </Form.Group>
                            {isLoading ?
                                <Button className="btn btn-gryfo-blue" disabled type="button">
                                    <i className="fas fa-spin fa-spinner" />&nbsp;
                                Carregando
                                </Button>
                                :
                                <Button className="btn btn-gryfo-blue" type="submit">Cadastrar</Button>
                            }
                        </Col>
                    </Form.Row>
                </Form>

                
            </div>

        </MainStructure>
    )
}