import React, { useEffect, useContext } from 'react'

import { useHistory } from "react-router-dom"

import { Context } from "../../services/Context"
export default function Home() {

    const history = useHistory()
    const { getAuthenticatedUser } = useContext(Context)

    useEffect(() => {

        function getUserAndRedirect() {
            getAuthenticatedUser().then(user => {
                if (user) {
                    if (user && user.business_model === 'client') {
                        history.push("/persons")
                    } else {
                        history.push("/companies")
                    }
                }
            })
        }
        getUserAndRedirect()
    }, [getAuthenticatedUser, history])



    return (
        <div></div>
    )
}
