import React, { useState, useContext, useEffect } from 'react'

import { Nav, Modal, Button, Navbar, Image } from 'react-bootstrap'
import { useLocation, Link, useHistory } from 'react-router-dom'
import { Context } from '../services/Context'

import logo from '../../assets/images/logo-gryfo-branco.svg'

export default function NavBar({ width, breakpoint }) {

    const { pathname } = useLocation()
    const { getAuthenticatedUser } = useContext(Context)
    const history = useHistory()
    const [showLogout, setShowLogout] = useState(false)
    const [user, setUser] = useState(false)

    useEffect(() => {
        if (!user) {
            getAuthenticatedUser().then(user => setUser(user))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function confirmLogout(e) {
        e.preventDefault()
        setShowLogout(true)
    }

    function closeLogoutModal() {
        setShowLogout(false)
    }

    function logout() {
        history.push("/logout")
    }

    return (
        <>
            <Navbar expand="md" className="bg-gryfo-blue">

                <Navbar.Brand bg="light" href="/">
                    <Image
                        src={logo}
                        width={100}
                    />

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <i className="fas fa-bars dark-side-link" />
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        {width <= breakpoint &&
                            <div className="my-2">

                                {user && user.access_level === 'admin' ?
                                    <Link className={`side-link ${pathname === "/users" ? "nav-link-active" : ""}`} to="/users">
                                        Usuários
                                    </Link>
                                    :
                                    ''
                                }
                                {user && user.access_level === 'admin' && user.business_model !== 'client' ?
                                    <Link className={`side-link ${pathname === "/companies" ? "nav-link-active" : ""}`} to="/companies">
                                        Empresas
                                    </Link>
                                    :
                                    ''
                                }

                                {user && user.access_level === 'admin' && user.business_model !== 'client' ?
                                    <Link className={`side-link ${pathname === "/groups" ? "nav-link-active" : ""}`} to="/groups">
                                        Grupos
                                    </Link>
                                    :
                                    ''
                                }


                                <Link className={`side-link ${pathname === "/persons" ? "nav-link-active" : ""}`} to="/persons">
                                    Pessoas
                                </Link>

                                <Link className={`side-link ${pathname === "/audit" ? "nav-link-active" : ""}`} to="/audit">
                                    Auditoria
                                </Link>

                                <Link className={`side-link ${pathname === "/services_status" ? "nav-link-active" : ""}`} to="/services_status">
                                    Status dos Serviços
                                </Link>

                                <Link className={`side-link ${pathname.includes("logout") ? "nav-link-active" : ""}`} to="/logout" onClick={confirmLogout} >
                                    Sair
                                </Link>
                            </div>

                        }

                    </Nav>
                    <Nav>
                        <Link className={`d-flex align-items-center dark-side-link ${width <= breakpoint ? 'mb-2' : ''}`} to="/user/account" >
                            <i className="fas fa-user-circle px-2" style={{ fontSize: 20 }} />
                            Minha Conta
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Modal dialogClassName="bg-gryfo-dark-blue" show={showLogout} onHide={closeLogoutModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Deseja sair?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Você tem certeza que deseja sair?</Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-gryfo-blue" onClick={closeLogoutModal}>Não</Button>
                    <Button className="btn btn-danger" onClick={logout}>Sim</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
