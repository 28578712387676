import React, { useState, useEffect, useContext, Fragment } from "react"
import { Highlighter, Token, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { Context } from '../services/Context'
import { handleError } from "../helpers/ErrorHelpers"


export default function CompaniesFilter({
    onSelectCompany,
    className="",
    labelKey='company_name',
    multiple=false,
    companyMode = false,
    placeholder = null,
    partnerId = null,
    disabled = false,
    ...rest
}) {

    const { getAxios } = useContext(Context)
    const axios = getAxios()

    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState([])
    const props = {};


    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled])

    useEffect(() => {
        loadCompanies()
        setCompany([])
        onSelectCompany(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partnerId])


    function filterAccordingToCompanyType(companies) {
        if (companyMode === "client") {
            companies = companies.filter(company => {
                if (partnerId) {
                    return company.partner_id === partnerId
                } else {
                    return company.partner_id != null
                }
            })
        } else if (companyMode === "partner") {
            companies = companies.filter(company => company.partner_id === null)
        }
        return companies
    }

    async function loadCompanies() {
        try {
            let response = await axios.get('/companies')
            if (response && response.data && response.data.success) {
                let companies = response.data.companies
                companies = filterAccordingToCompanyType(companies)
                setCompanies(companies)
            }
        } catch (error) {
            handleError(error, "UserList", "loadCompanies")
        }
    }

    async function handleChangeCompany(company) {
        setCompany(company)
        if(!multiple) onSelectCompany(company[0])
        else onSelectCompany(company)
    }

    props.renderMenuItemChildren = (option, { text }) => (
        <Fragment>
          <Highlighter search={text}>
            {option.company_name}
          </Highlighter>
          <div>
            <small>
              External ID: {option.external_id}
            </small>
          </div>
        </Fragment>
    );

    props.renderToken = (option, { onRemove }, index) => (
        <Token
            key={index}
            onRemove={onRemove}
            option={option}>
            {`${option.company_name} (ID: ${option.external_id})`}
        </Token>
    );

    return (

        <div className={className}>
            <Typeahead
                {...props}
                id="basic-typeahead-single"
                clearButton
                multiple={multiple}
                filterBy={['company_name', 'external_id']}
                labelKey={labelKey}
                onChange={handleChangeCompany}
                options={companies}
                placeholder={placeholder ? placeholder : "Empresa"}
                selected={company}
            />
        </div>
    )
}
