import React, { useState, useEffect, useContext } from "react"

import { Row, Col, Form, Image, Button, Modal } from "react-bootstrap"
import { Link, useParams, useHistory } from "react-router-dom"

import { Context } from '../../services/Context'
import { handleError } from "../../helpers/ErrorHelpers"
import { loadCamera, stopCamera, checkHasCam, convertBase64, videoToBase64Image, checkIsAMobile } from "../../helpers/MediaHelpers"
import Status from "../../templates/Status"
import Loader from "../../templates/Loader"
import MainStructure from "../../structures/MainStructure"

export default function UpdatePerson() {

    const { getAxios } = useContext(Context)
    const axios = getAxios()
    const history = useHistory()
    const { updatePersonId } = useParams()
    const [name, setName] = useState("")
    const [externalId, setExternalId] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [isSimilar, setIsSimilar] = useState(false)
    const [rawImages, setRawImages] = useState([])
    const [previousImages, setPreviousImages] = useState([])
    const [previousBase64Images, setPreviousBase64Images] = useState([])
    const [status, setStatus] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [details, setDetails] = useState([])

    const [showModal, setShowModal] = useState(false)

    const [hasACamera, setHasACamera] = useState(false)
    const [cameraMode, setCameraMode] = useState("user")
    const [isAMobile] = useState(checkIsAMobile())
    const [isLoadingVideo, setIsLoadingVideo] = useState(true)

    const [isCapturing, setIsCapturing] = useState(false)
    const [imagePreview, setImagePreview] = useState(false)
    const [showModalPreview, setShowModalPreview] = useState(false)

    useEffect(() => {
        checkAndGetUpdatePersonData()
        checkIfHasCamera()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (status) setStatus(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, rawImages])


    useEffect(() => {
        getImages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousImages])

    function closeModal() {
        let video = document.querySelector(".web_camera")
        if (video) {
            stopCamera(video)
        }
        setShowModal(false)
    }

    function closeModalPreview() {
        setShowModalPreview(false)
    }

    async function checkIfHasCamera() {
        if (await checkHasCam()) {
            setHasACamera(true)
        }
    }

    async function checkAndGetUpdatePersonData() {
        if (!updatePersonId) {
            history.replace("/persons")
            return
        }

        let person = await getPersonData()

        if (!person) {
            history.replace("/persons")
            return
        }

        setName(person.name)
        setExternalId(person.external_id)
        setCompanyName(person.company_name)
        setPreviousImages(person.photos_paths)
        setDetails([])
        setIsSimilar(person.is_similar)
    }

    async function getPersonData() {
        let person = false
        try {
            let response = await axios.get(`/person/${decodeURI(updatePersonId)}/storage`)
            if (response && response.data.success) {
                person = response.data.person
                person = person.is_active ? person : false
            }
        } catch (error) {
            handleError(error, "UpdatePerson", "getPersonData", { personName: decodeURI(updatePersonId) })
        }
        return person
    }

    async function getImages(){
        let images = []
        let success = true
        for(var image of previousImages){
            let base64 = await axios.get(image.image_path, { responseType: 'arraybuffer' })
            .then((response) => {
                let b64string = btoa(
                    new Uint8Array(response.data)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                return `data:${response.headers['content-type'].toLowerCase()};base64,${b64string}`;
                // eslint-disable-next-line no-loop-func
            }).catch((error)=>{
                
                success = false
            });
            images.push(base64)
        }
        setRawImages(images)
        setPreviousBase64Images(images)
        if (!success) {
            let status = { 
                success: false, 
                // eslint-disable-next-line no-multi-str
                message: "Ops! Está ocorrendo algum erro com o servidor e \
                            não é possivel carregar as imagens \
                            por favor tente mais tarde"
            }
            setStatus(status)
        }
    }

    async function handleSubmit(e) {
        e.preventDefault()

        if (!checkFormIsValid()) return

        let status = await postPerson()

        if (Object.keys(status).includes('data')) {
            if (status.success){
                let details = []
                status.data.additions.map(image => details.push({success: image.success, message: image.message}))
                if(details.length !== rawImages.length){
                    details = [...Array(rawImages.length-details.length), ...details]
                }
                setDetails(details)
            } else {
                let errors = []
                status.data.additions.map(image => errors.push({success: image.success, message: image.message}))
                if(errors.length !== rawImages.length){
                    errors = [...Array(rawImages.length-errors.length), ...errors]
                }
                setDetails(errors)
            }
        }

        setStatus(status)
    }

    function handleCheck(e){
        setIsSimilar(e.target.checked)
    }

    function checkFormIsValid() {
        if (!name || !externalId) return false

        if (!rawImages || rawImages.length === 0) {
            setStatus({ success: false, message: "É necessário enviar pelo menos uma imagem" })
            return false
        }
        return true
    }

    function setAdditionsDeletions(){
        let additions = []
        let deletions = []

        for (let [index, prevImage] of previousImages.entries()) {
            if(!rawImages.includes(previousBase64Images[index])){
                deletions.push(prevImage.image_id)
            }
        }

        for (let image of rawImages) {
            if(!previousBase64Images.includes(image)){
                additions.push(image)
            }
        }

        return [ additions, deletions ]
    }

    async function postPerson() {
        let status = { success: false, message: "Não foi possível editar a Pessoa" }
        setIsLoading(true)
        try {
            let [ additions, deletions ] = setAdditionsDeletions()
            let response = await axios.post(`/person/update/${updatePersonId}/storage`, { 
                name: name, is_similar: isSimilar, additions , deletions
            })
            if (response && response.data.message) {
                status = response.data
            }
        } catch (error) {
            handleError(error, "AddPerson", "PostPerson", { name, updatePersonId })
        }
        setIsLoading(false)
        return status
    }

    async function addImage(file) {
        if (!file) {
            setStatus({ success: false, message: "Arquivo inválido" })
            return
        }
        if (!file.type.includes("png") && !file.type.includes("jpg") && !file.type.includes("jpeg")) {
            setStatus({ success: false, message: "A imagem precisa estar em um dos seguintes formatos: JPG, PNG, JPEG" })
            return
        }

        let image = await convertBase64(file)

        if (image) pushCollectionOfBase64Images(image)
        else setStatus({ success: false, message: "Ocorreu um erro ao pré-processar essa imagem" })

        clearInput(document.querySelector("#input_upload"))
    }

    function clearInput(input) {
        if (input) {
            input.value = ""
        }
    }

    async function pushCollectionOfBase64Images(base64) {

        if (rawImages.filter(rawImagesArray => rawImagesArray === base64).length) {
            setStatus({ success: false, message: "Essa imagem já foi enviada" })
            return
        }

        let newRawImages = [...rawImages]
        newRawImages.push(base64)
        setRawImages(newRawImages)
    }

    function removeImage(base64) {
        let newRawImages = rawImages.filter(originalBase64 => originalBase64 !== base64)
        setDetails([])
        setRawImages(newRawImages)
    }

    async function handleLoadCamera() {

        let video = document.querySelector(".web_camera")

        if (video) {
            setIsLoadingVideo(true)
            await loadCamera(video)
            setIsLoadingVideo(false)
        }
    }

    async function capture() {
        const video = document.querySelector(".web_camera")
        const maxTime = 20000
        let time = 0
        const intervalNumber = 400

        setIsCapturing(true)
        let base64 = await new Promise((resolve) => {
            let interval = setInterval(async () => {
                let image = videoToBase64Image(video)

                if (image) {
                    clearInterval(interval)
                    resolve(image)
                } else if (time >= maxTime) {
                    clearInterval(interval)
                    resolve(false)
                }
                time += intervalNumber
            }, intervalNumber)
        })
        if (base64) {
            closeModal()
            setImagePreview(base64)
            setShowModalPreview(true)
        }
        setIsCapturing(false)
    }

    function confirmPreview() {
        let base64 = imagePreview
        pushCollectionOfBase64Images(base64)
        setShowModalPreview(false)
        setImagePreview(false)
    }

    function cancelPreview() {
        setShowModalPreview(false)
        setShowModal(true)
    }

    async function handleChangeCameraMode() {
        let video = document.querySelector(".web_camera")

        if (video) {
            setIsLoadingVideo(true)
            stopCamera(video)
            let newMode = cameraMode === "user" ? "environment" : "user"
            setCameraMode(newMode)
            await loadCamera(video, { facingMode: newMode })
            setIsLoadingVideo(false)
        }

    }

    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">EDITAR PESSOA</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/persons">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-arrow-left mr-1" /> Voltar
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={12}>
                        <Form onSubmit={handleSubmit}>
                            <Status status={status} />

                            <Form.Group>
                                <Form.Label>Nome da Pessoa</Form.Label>
                                <Form.Control type="text" value={name} onChange={e => setName(e.target.value)} placeholder="Insira o Nome da Pessoa" disabled={status.success} required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Id Externo (rg, cpf)</Form.Label>
                                <Form.Control type="text" value={externalId} disabled placeholder="Insira o Id Externo" required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Existe outra pessoa semelhante a esta?</Form.Label>
                                <Form.Check type="checkbox" checked={isSimilar} value={isSimilar} onChange={handleCheck} disabled={status.success} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Empresa</Form.Label>
                                <Form.Control type="text" value={companyName} disabled />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Upload de Imagens</Form.Label>
                                <div className="d-flex flex-row">
                                    <Form.File
                                        id="input_upload"
                                        label="Clique para fazer upload"
                                        onChange={e => addImage(e.target.files[0])}
                                        className="mr-2"
                                        custom
                                        disabled={status.success}
                                    />
                                    {hasACamera ?
                                        <Button className="btn btn-gryfo-blue height-input" onClick={() => setShowModal(true)}><i className="fas fa-camera" /></Button>
                                        :
                                        <Button className="btn btn-gryfo-blue height-input" disabled><i className="fas fa-camera" /></Button>
                                    }

                                </div>
                                <div className="d-flex flex-container">
                                    {(rawImages && rawImages.length > 0) &&
                                        rawImages.map((rawImage, index) =>
                                            <div key={'update_photo-'+index} className="d-flex flex-column align-items-start mx-1 my-2">
                                                <i className="fas fa-trash image_button text-white bg-danger" hidden={status.success} onClick={() => removeImage(rawImage)} />
                                                <Image src={rawImage} className="image_thumbnail" />
                                                {details[index] ?
                                                    <div className="image_error_text">
                                                        <Status status={details[index]} />
                                                    </div>
                                                    :''}
                                            </div>
                                        )

                                    }
                                </div>
                            </Form.Group>
                            <hr />
                            {isLoading ?
                                <Button className="btn btn-gryfo-blue" disabled type="button">
                                    <i className="fas fa-spin fa-spinner" />&nbsp;
                                    Carregando
                                </Button>
                                :
                                <>
                                    <Button className="btn btn-gryfo-blue" onClick={checkAndGetUpdatePersonData} hidden={!status.success}>
                                        <i className="fas fa-sync" />&nbsp;
                                        Recarregar
                                    </Button>
                                    <Button className="btn btn-gryfo-blue" type="submit" hidden={status.success}>
                                        Alterar
                                    </Button>
                                </>
                            }
                        </Form>
                    </Col>
                </Row>
            </div>
            <canvas id="imgCanvas" />
            <Modal
                dialogClassName={`modal-full-screen ${isCapturing ? "detecting" : ""}`}
                show={showModal}
                onEntered={handleLoadCamera}
                onHide={closeModal}
            >
                {isLoadingVideo && <Loader />}
                <div className="modal-video-header">
                    <Button className="btn btn-danger m-3" onClick={closeModal}>
                        <i className="fas fa-times" />
                    </Button>
                </div>

                <video autoPlay={true} className="col-12 web_camera"></video>
                {!isLoadingVideo &&
                    <div className="modal-video-footer">
                        {(isAMobile) &&
                            <Button className="btn btn-gryfo-blue m-2" onClick={handleChangeCameraMode}> <i className="fas fa-redo" /> Inverter Camera</Button>
                        }
                        {isCapturing ?
                            <Button className="btn btn-gryfo-blue m-3 disabled" > <i className="fas fa-spin fa-spinner" /> Processando</Button>
                            :
                            <Button className="btn btn-gryfo-blue m-3" onClick={capture}> <i className="fas fa-vector-square" /> Capturar</Button>
                        }
                    </div>
                }

            </Modal>
            <Modal
                dialogClassName={`modal-full-screen ${isCapturing ? "detecting" : ""}`}
                animation={false}
                show={showModalPreview}
                onHide={closeModalPreview}
            >
                <div className="modal-video-header">
                    <Button className="btn btn-danger m-3" onClick={closeModalPreview}>
                        <i className="fas fa-times" />
                    </Button>
                </div>

                {(imagePreview) && <Image src={imagePreview} className="web_camera_preview" />}

                <div className="modal-video-footer">
                    <Button className="btn btn-danger m-3" onClick={cancelPreview}> <i className="fas fa-times" /> Cancelar</Button>
                    <Button className="btn btn-gryfo-blue m-3" onClick={confirmPreview}> <i className="fas fa-check" /> Confirmar</Button>
                </div>

            </Modal>
        </MainStructure>
    )
}
