import React, { useEffect, useState, useContext } from "react"
import { Image } from "react-bootstrap"
import Loader from "./Loader"
import { Context } from '../services/Context'

export default function PrivateImage({ uri, ...rest }) {

    const { getAxios } = useContext(Context)
    const axios = getAxios()
    const [imageFile, setImageFile] = useState(null)
    useEffect(() => {
        loadImageFile(uri)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uri])

    async function loadImageFile(uri) {
        let base64 = await axios.get(uri, { responseType: 'arraybuffer' })
            .then((response) => {
                let b64string = btoa(
                    new Uint8Array(response.data)
                        .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                return `data:${response.headers['content-type'].toLowerCase()};base64,${b64string}`;
            }).catch((error) => {
                console.log(error)
                return null
            })
        if (base64) {
            setImageFile(base64)
        }
    }

    return (
        <Loader show={imageFile}>
            <Image src={imageFile} {...rest} />
        </Loader>
    )

}