import React from 'react'

import { Row, Col, Card, Button } from 'react-bootstrap'
import PrivateImage from "../../templates/PrivateImage"
import moment from 'moment'
import TooltipTemplate from '../../templates/TooltipTemplate'

export default function auditionDashboard({ auditions, handleAuditAction }) {

    const categoryDict = {
        fraud_evidence: {
            text: "Indicio de fraude",
            color: 'gryfo-dark-yellow',
        },
        not_a_fraud: {
            text: "Não é fraude",
            color: "gryfo-dark-blue",
        },
        error: {
            text: "Erro na auditoria",
            color: "gryfo-dark-grey",
        }
    }

    return (
        <Col>
            <Row className="audition-cards-container">
                {auditions.map(audition => {
                    return (
                        <Card key={audition._id} className="audition-card-box d-flex mb-5">
                            <Card.Header className={`text-center text-white bg-${categoryDict[audition.category.toLowerCase()].color}`}>
                                <b>{categoryDict[audition.category.toLowerCase()].text}</b>
                            </Card.Header>
                            <Card.Body className="audition-card-body">
                                {
                                    audition.audit_image && audition.audit_image.image_path ?
                                    <PrivateImage
                                        uri={audition.audit_image.image_path}
                                        className="audition-card-image my-1"
                                    />
                                    :
                                    <Card.Text className="audition-card-text">
                                        <b>ERRO NO CARREGAMENTO DA IMAGEM</b>
                                    </Card.Text>
                                }
                                <Card.Text className="audition-card-text">
                                    <b>CAMERA {audition.camera}</b>
                                </Card.Text>
                                <Card.Text className="audition-card-text">
                                    <b>{moment(audition.last_recognized * 1000).format("DD-MM-YYYY HH:mm:ss")}</b>
                                </Card.Text>
                                
                                <div className="d-flex justify-content-center">
                                    <TooltipTemplate legend="Descartar">
                                        <Button className="btn bg-gryfo-blue d-flex align-items-center mr-1" onClick={() => handleAuditAction("discard", audition._id)}>
                                            <i className="fas fa-minus-square" style={{ fontSize: "14pt", width: "20%" }} />
                                        </Button>
                                    </TooltipTemplate>


                                    <TooltipTemplate legend="Reportar">
                                        <Button className="btn btn-danger d-flex align-items-center ml-1" onClick={() => handleAuditAction("report", audition._id)}>
                                            <i className="fas fa-exclamation-triangle" style={{ fontSize: "14pt", width: "20%" }} />
                                        </Button>
                                    </TooltipTemplate>
                                </div>

                            </Card.Body>
                        </Card>
                    )
                })}
            </Row>
        </Col>

    )
}
