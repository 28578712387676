import React, {useState, useEffect, useContext} from 'react'
import Collapsible from 'react-collapsible';
import { Context } from '../services/Context'
import { Modal, Button } from 'react-bootstrap'

export default function Service({ status }) {

    const [trigger, setTrigger] = useState("Ver detalhes")
    const { getAxios, getAuthenticatedUser } = useContext(Context)
    const [currentEndpoint, setCurrentEndpoint] = useState(0)
    const [showResponse, setShowResponse] = useState(false)


    function openResponseModal(index) {
        setCurrentEndpoint(index)
        setShowResponse(true)
    }

    function closeResponseModal() {
        setShowResponse(false)
    }

    function showDetails(details){
        let detailsContent = []
        details.forEach(function(detail){
            detailsContent.push(detail)
        })
        return detailsContent
    }
    let serviceStatus = <></>;
    let details = showDetails(status.details)
    //console.log(status.details)
    // let details = <></>
    if(status.status == 'ok'){
        serviceStatus = <span style={{padding: '10px'}} className="status-ok">{status.message}</span>
    }
    else if(status.status == 'not_ok'){
        serviceStatus = <span style={{padding: '10px'}} className="status-danger">{status.message}</span>
    }
    else{
        serviceStatus = <span style={{padding: '10px'}} className="status-updating"><i className="fas fa-spin fa-spinner" />&nbsp;&nbsp;{status.message}</span>
    }
    return (
        status &&
        <div>
            <div className="status">
                    {serviceStatus}
            </div>
            <div>
                <Collapsible trigger={trigger} triggerStyle={{backgroundColor: "#3578dc", color: '#ffffff', padding: '5px', borderRadius: '5px'}} onOpen={()=> {setTrigger('Ocultar detalhes');}} onClose={()=> {setTrigger('Ver detalhes');}}>
                <div className="table-responsive">
                    <table id="users-table" className="table table-striped table-bordered" width="100%" style={{margin: '10px'}}>
                        <thead className="bg-gryfo-blue text-white">
                            <tr>
                                <td style={{padding:'10px'}}>Enpoint</td>
                                {   status.userbusinessModel == 'gryfo'? 
                                        <td style={{padding:'10px', maxWidth: '400px', cursor: 'pointer'}}>Mensagem</td>
                                        :
                                        ""   
                                }
                                {   status.userbusinessModel == 'gryfo'? 
                                        <td style={{padding:'10px', maxWidth: '400px'}}>Resposta do Endpoint</td>
                                        :
                                        ""   
                                }
                                <td style={{padding:'10px'}}>Status</td>
                                <td style={{padding:'10px'}}>Última atualização</td>
                            </tr>
                        </thead>
                        
                        <tbody>
                            {   status.userbusinessModel == 'gryfo'? details.map((detail, index) => <tr><td style={{padding:'10px'}}>{detail['endpoint']}</td><td style={{padding:'10px', maxWidth: '400px', fontSize:"17px"}}>{detail['message']}</td><td style={{padding:'10px', maxWidth: '400px'}}><Button className="btn btn-gryfo-blue" onClick={()=>{openResponseModal(index);}}> Resposta </Button></td><td style={{padding:'10px'}}>{detail['status']}</td><td style={{padding:'10px'}}>{detail['last_update']}</td></tr>)
                                :
                                details.map((detail, index) => <tr><td style={{padding:'10px'}}>{detail['endpoint']}</td><td style={{padding:'10px'}}>{detail['status']}</td><td style={{padding:'10px'}}>{detail['last_update']}</td></tr>)    
                            }
                        </tbody>
                    </table>
                </div>
                </Collapsible>
                
            </div>
            {status.details.length > 0?
                <Modal dialogClassName="bg-gryfo-dark-blue" show={showResponse} onHide={closeResponseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Resposta do Endpoint</Modal.Title>
                    </Modal.Header>
                    <Modal.Body><div>{JSON.stringify(status.details[currentEndpoint]['response'], null, 2)}</div></Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-gryfo-blue" onClick={closeResponseModal}> Fechar </Button>
                    </Modal.Footer>
                </Modal>
            :
                <></>
            }
           
        </div>
    );
}