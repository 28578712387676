
import React, { forwardRef } from 'react'
import { InputGroup, Form } from "react-bootstrap"
import "react-datepicker/dist/react-datepicker.css"
import pt from "date-fns/locale/pt";
import DatePicker, { registerLocale } from "react-datepicker"
registerLocale("pt", pt);

export default function DateFilter({ startDate, label, setStartDate, endDate = null, required = false, ...rest }) {
    const DatePickerFormInput =
        forwardRef(({ text, onClick, value }, ref) => (
            <InputGroup size="sm">
                <InputGroup.Prepend>
                    <InputGroup.Text className="justify-content-center"><i className="fas fa-history mr-2"></i>{text}</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    required={required}
                    value={value}
                    type="text"
                    placeholder=""
                    className="text-center"
                    onClick={onClick}
                    onChange={() => { return null }}
                />
            </InputGroup>
        ))

    return (
        <DatePicker
            customInput={<DatePickerFormInput text={label} />}
            selected={startDate}
            maxDate={endDate}
            onChange={date => setStartDate(date)}
            showPopperArrow={false}
            showTimeInput
            timeInputLabel="Hora:"
            dateFormat="dd/MM/yyyy h:mm aa"
            locale="pt"
            {...rest}
        />
    )
}