import React, { useState, useEffect, useContext } from "react"

import $ from 'jquery'
import { Row, Col, Button, Form } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"

import { Context } from '../../services/Context'
import MainStructure from "../../structures/MainStructure"
import Loader from '../../templates/Loader'
import datatablePt from "../../../assets/lang/datatablePt.json"
import BusinessModelFilter from "../../templates/BusinessModelFilter"
import IsActiveFilter from "../../templates/IsActiveFilter"
import CompaniesFilter from "../../templates/CompaniesFilter"

import { handleError } from "../../helpers/ErrorHelpers"

$.DataTable = require("datatables.net")

export default function UserList() {

    const { getAxios } = useContext(Context)
    const axios = getAxios()
    const history = useHistory()
    const [users, setUsers] = useState(false)
    const [businessModel, setBusinessModel] = useState("")
    const [selectedCompany, setSelectedCompany] = useState("")
    const [inactive, setInactive] = useState(false)

    useEffect(() => {
        loadUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inactive, businessModel, selectedCompany])

    useEffect(() => {
        loadDataTable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users])

    async function loadUsers(e = null) {
        if (e) e.preventDefault()
        let users = await getUsers()
        setUsers(users)
    }

    async function getUsers() {
        let users = false
        try {
            let filter = { is_active: !inactive }
            if (selectedCompany) filter.company_id = selectedCompany['_id']

            let response = await axios.post('/users', filter)
            if (response && response.data && response.data.success) {
                users = response.data.users
                users = filterUsers(users)
            } else {
                users = []
            }
        } catch (error) {
            handleError(error, "UserList", "loadUsers")
        }
        return users
    }

    function filterUsers(users) {
        if (businessModel) {
            users = users.filter((user) => user.business_model === businessModel)
        }
        return users
    }

    function onChangeInactive(active) {
        setInactive(!active)
    }

    function clearDataTable() {
        $('#users-table').DataTable().clear()
        $('#users-table').DataTable().destroy()
    }

    function loadDataTable() {
        if ($('#users-table').DataTable()) {
            clearDataTable()
        }
        let data = []
        if (users && users.length > 0) {
            users.forEach(item => {
                data.push([item.username, item._id])
            })
            var table = $('#users-table').DataTable({
                dom: 'Bitp',
                "language": datatablePt,
                data: data,
                "columnDefs": [
                    {
                        "data": null,
                        "render": function (data, type, row, meta) {
                            let userName = row[0]
                            return `<div class="text-center" style="font-size: 10pt;">
                                        <i id="edit-btn-`+ userName + `" name="edit-btn" class="m-1 h4 text-gryfo-dark-yellow cursor-pointer fas fa-edit" data-toggle="tooltip" data-placement="left" title="Editar Usuário"></i>
                                        ` + (!inactive ?
                                    `<i id="deact-btn-` + userName + `" name="deact-btn" class="m-1 h4 cursor-pointer fas fa-ban" data-toggle="tooltip" data-placement="left" title="Desativar Usuário"></i>` :
                                    `<i id="act-btn-` + userName + `" name="act-btn" class="m-1 h4 cursor-pointer fas fa-handshake text-success" data-toggle="tooltip" data-placement="left" title="Reativar Usuário"></i>`
                                ) +
                                `<i id="rm-btn-` + userName + `" name="rm-btn" class="m-1 h4 text-danger cursor-pointer fas fa-trash" data-toggle="tooltip" data-placement="left" title="Remover Usuário"></i>
                                    </div>`
                        },
                        "targets": -1
                    }
                ]
            })



            $('body').on('click', "i[name='edit-btn']", function () {
                var data = table.row($(this).parents('tr')).data();
                if (!data || data.length === 0) return
                history.push('/user/update/' + data[1])
            });

            $('body').on('click', "i[name='deact-btn']", async function () {
                var data = table.row($(this).parents('tr')).data();
                if (!data || data.length === 0) return
                let response = await axios.post('/user/update/' + data[1], { 'is_active': false })
                if (response && response.data && response.data.success) {
                    loadUsers()
                }
            });

            $('body').on('click', "i[name='act-btn']", async function () {
                var data = table.row($(this).parents('tr')).data();
                if (!data || data.length === 0) return
                let response = await axios.post('/user/update/' + data[1], { 'is_active': true })
                if (response && response.data && response.data.success) {
                    loadUsers()
                }
            });

            $('body').on('click', "i[name='rm-btn']", async function () {
                var data = table.row($(this).parents('tr')).data();
                if (!data || data.length === 0) return

                if (!window.confirm("Deseja mesmo remover este usuário?")) return
                let response = await axios.delete('/user/delete/' + data[1])
                if (response && response.data && response.data.success) {
                    loadUsers()
                }
            });
        }
    }

    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">USUÁRIOS</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/user/add">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-plus mr-1" /> Cadastrar Usuário
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <Loader show={users}>
                    <Form onSubmit={loadUsers}>
                        <Form.Row>
                            <CompaniesFilter 
                                className="form-group my-2 col-12 col-md align-self-center"
                                onSelectCompany={setSelectedCompany} 
                            />
                            <BusinessModelFilter onSelectBusinessModel={setBusinessModel} />
                            <IsActiveFilter onChange={onChangeInactive} />
                        </Form.Row>

                    </Form>
                    {(users && users.length > 0) ?
                        <div className="table-responsive">
                            <table id="users-table" className="table table-striped table-bordered" width="100%">
                                <thead className="bg-gryfo-blue text-white">
                                    <tr>
                                        <th>NOME</th>
                                        <th className="text-center" style={{ maxWidth: "300px" }}>AÇÕES</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        :
                        'Não há usuários cadastrados'
                    }
                </Loader>
            </div>
        </MainStructure>
    )
}