export const setStorages = async (data) => {
    let { token, refreshToken } = data
    localStorage.setItem("@token", token)
    localStorage.setItem("@refresh_token", refreshToken)
}

export const getStorageToken = () => localStorage.getItem("@token")

export const getStorageRefreshToken = () => localStorage.getItem("@refresh_token")

export const resetUserStorages = () => {
    localStorage.removeItem("@token")
    localStorage.removeItem("@refresh_token")
}