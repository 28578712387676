import React from 'react'

import { Pagination, Form} from "react-bootstrap"

export default function Paginator({ actual, last, entries, update }) {

    const entriesList = [
        { value:6, key:'limit-select-0' },
        { value:12, key:'limit-select-1' },
        { value:24, key:'limit-select-2' },
        { value:48, key:'limit-select-3' }
    ]
    const handlePage = (page, limit=entries) => {
        update(page, limit)
    }

    return (
        <div>
            <Pagination hidden={last<1} className="paginator">
                <Form.Control className="mr-3" style={{width:"65px"}} as="select" onChange={(e)=> handlePage(actual, e.target.value)} value={entries}>
                    {entriesList.map(item =>
                        <option value={item.value} key={item.key}>{item.value}</option>
                    )}
                </Form.Control>
                {actual > 2 ? <Pagination.First onClick={()=>handlePage(1)} />:''}
                {actual > 1 ? <Pagination.Prev onClick={()=>handlePage(actual-1)} />:''}

                <Pagination.Item active style={{zIndex: -1}}>{actual}</Pagination.Item>

                {actual < last ? <Pagination.Next onClick={()=>handlePage(actual+1)} />:''}
                {actual < last-1 ? <Pagination.Last onClick={()=>handlePage(last)} />:''}
            </Pagination>
        </div>
    )
}
