import React, { useState, useContext, useEffect } from "react"

import { Form, Button } from "react-bootstrap"
import { useHistory, useLocation } from "react-router-dom"

import AuthenticationStructure from "../../structures/AuthenticationStructure"
import { Context } from "../../services/Context"
import Status from "../../templates/Status"

export default function Login() {

    let history = useHistory()
    const { state } = useLocation()
    const { isAuthenticated, doLogin, resetStates } = useContext(Context)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [status, setStatus] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (isAuthenticated) {
            history.push("/")
        }
        if (state) {
            if (state.hasOwnProperty("message")) {
                setStatus({ 'success': false, 'message': state.message, 'expired': true })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, history, state])

    useEffect(() => {
        if (!status.expired) setStatus(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, password])


    async function handleSubmit(e) {
        e.preventDefault()
        resetStates()
        if (!username || !password) return
        setIsLoading(true)
        let response = await doLogin(username, password)
        if (response.success) {
            history.push("/")
        } else {
            setStatus(response)
            setIsLoading(false)
        }
    }

    return (
        <AuthenticationStructure>
            <Form onSubmit={handleSubmit}>
                <Status status={status} />
                <Form.Group>
                    <Form.Label>Nome de Usuário</Form.Label>
                    <Form.Control type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="Insira o nome de usuário" required />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Senha</Form.Label>
                    <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Insira a sua senha" required />
                </Form.Group>
                {isLoading ?
                    <Button className="btn btn-gryfo-blue btn-width-small" disabled type="button">
                        <i className="fas fa-spin fa-spinner" />&nbsp;
                        Carregando
                    </Button>
                    :
                    <Button className="btn btn-gryfo-blue btn-width-small" type="submit">Entrar</Button>
                }
            </Form>
        </AuthenticationStructure>
    )
}