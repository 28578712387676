import React, { useState, useContext, useEffect } from "react"

import { Row, Col, Form, Button } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"

import { Context } from '../../services/Context'
import { handleError } from "../../helpers/ErrorHelpers"
import MainStructure from "../../structures/MainStructure"
import Status from "../../templates/Status"

export default function UpdateUser() {

    const { getAxios, getAuthenticatedUser } = useContext(Context)
    const axios = getAxios()
    const history = useHistory()
    const [userId, setUserId] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [rePassword, setRePassword] = useState("")
    const [accessLevel, setAccessLevel] = useState("admin")
    const [businessModel, setBusinessModel] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [status, setStatus] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (status) setStatus(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, password, rePassword, accessLevel])

    useEffect(() => {
        async function checkAndGetUpdateUserData() {
            let user = await getAuthenticatedUser()
            if (!user) {
                history.replace("/users")
                return
            }
            setUserId(user._id)
            setUsername(user.username)
            setAccessLevel(user.access_level)
            setBusinessModel(user.business_model)
            if (user.company_id !== null){
                getCompanyName(user.company_id).then(company => {
                    setCompanyName(company.company_name)
                })
            }else{
                setCompanyName('gryfo')
            }

        }
        checkAndGetUpdateUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getCompanyName(companyId){
        let company = false
        try{
            let response = await axios.get(`/company/${companyId}/storage`)
            if (response && response.data) {
                company = response.data.company
            }
        } catch (error) {
            handleError(error, "UpdateUser", "getCompanyName", { companyId: companyId })
        }
        return company
    }

    async function handleSubmit(e) {
        e.preventDefault()

        if (!checkFormIsValid()) return

        let status = await postUser()

        if (status.success) {
            status.message = "Usuário atualizado com sucesso!"
        }

        setStatus(status)
    }

    function checkFormIsValid() {
        if (!username) return false

        if (password !== rePassword) {
            setStatus({ success: false, message: "As senhas precisam ser iguais" })
            return false
        }
        return true
    }

    async function postUser() {
        setIsLoading(true)
        let status = { success: false, message: "Não foi possível atualizar o Usuário" }
        try {
            let data = {}

            if (username) {
                data['username'] = username
            }
            if (password) {
                data['password'] = password
            }
            let response = await axios.post(`/user/update/${userId}`, data)

            if (response && response.data && response.data.success) {
                status = response.data
            }
        } catch (error) {
            handleError(error, "UserAccount", "postUser", username)
        }
        setIsLoading(false)
        return status
    }

    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">MINHA CONTA</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/users">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-arrow-left mr-1" /> Voltar
                        </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Status status={status} />
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>Nome de Usuário</Form.Label>
                                <Form.Control type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="Insira o nome de usuário" required />
                            </Form.Group>
                            {businessModel !== 'gryfo' ?
                                <>
                                    <Form.Group>
                                        <Form.Label>Nova Senha</Form.Label>
                                        <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Insira a nova senha" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Nova Senha</Form.Label>
                                        <Form.Control type="password" value={rePassword} onChange={e => setRePassword(e.target.value)} placeholder="Repita a nova senha" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Empresa a que pertence</Form.Label>
                                        <Form.Control disabled type="text" value={companyName} />
                                    </Form.Group>
                                    {/* <Form.Group>
                                        <Form.Label>Nível de Acesso</Form.Label>
                                        <Form.Check
                                            disabled
                                            checked={accessLevel === "admin"}
                                            type="radio"
                                            label="Administrador"
                                            name="accessLevel"
                                            id="admin"
                                        />
                                        <Form.Check
                                            disabled
                                            checked={accessLevel === "operational"}
                                            type="radio"
                                            label="Operador"
                                            name="accessLevel"
                                            id="operational"
                                        />
                                    </Form.Group> */}
                                    <hr />
                                </>
                                :
                                <hr />
                            }
                            {isLoading ?
                                <Button className="btn btn-gryfo-blue" disabled type="button">
                                    <i className="fas fa-spin fa-spinner" />&nbsp;
                                Carregando
                            </Button>
                                :
                                <Button className="btn btn-gryfo-blue" type="submit">Atualizar</Button>
                            }
                        </Col>
                    </Form.Row>
                </Form>
            </div>
        </MainStructure>
    )
}