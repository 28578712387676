
import React from 'react'
import * as Sentry from "@sentry/react";

import { BrowserRouter } from "react-router-dom"

import Routes from "./Routes"

import Error from './components/pages/error/Error'

export default function App() {
    return (
        <Sentry.ErrorBoundary
            showDialog
            dialogOptions={{
                title: "AJUDE NOS COM FEEDBACK",
                subtitle: "Nosso time será notificado.",
                subtitle2: "Por favor descreva o que ocorreu.",
                labelName: "Nome",
                labelComments: "Descrição",
                labelClose: "Fechar",
                labelSubmit: "Enviar",
                labelEmail: "Email",
                errorGeneric: "Opa!, houve um outro erro durante o envio. Por favor, não desista e tente novamente, nosso time agradece",
                errorFormEntry: "Alguns dos campos estão errados. Corrija-os e tente novamente",
                successMessage: "Feedback enviado, Obrigado!"
            }}
            fallback={({ error, componentStack, resetError }) => (<React.Fragment><Error /></React.Fragment>)}>
            <BrowserRouter>
                <div className="app">
                    <Routes />
                </div>
            </BrowserRouter>
        </Sentry.ErrorBoundary>

    )
}

