import React, { useState, useEffect, useContext } from "react"

import { Row, Col, Form, Image, Button } from "react-bootstrap"
import { Link, useParams, useHistory } from "react-router-dom"

import { Context } from '../../services/Context'
import { handleError } from "../../helpers/ErrorHelpers"
import MainStructure from "../../structures/MainStructure"

import Status from "../../templates/Status"

export default function Person() {

    const { getAxios } = useContext(Context)
    const history = useHistory()
    const { personId } = useParams()
    const axios = getAxios()
    const [name, setName] = useState("")
    const [externalId, setExternalId] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [rawImages, setRawImages] = useState([])
    const [b64Images, setb64Images] = useState([])
    const [status, setStatus] = useState(false)

    useEffect(() => {
        checkAndGetUpdatePersonData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getImages()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rawImages])

    async function checkAndGetUpdatePersonData() {
        if (!personId) {
            history.replace("/persons")
            return
        }

        let person = await getPersonData()

        if (!person) {
            history.replace("/persons")
            return
        }

        setName(person.name)
        setExternalId(person.external_id)
        setCompanyName(person.company_name)
        setRawImages(person.photos_paths)
    }


    async function getPersonData() {
        let person = false
        try {
            let response = await axios.get(`/person/${decodeURI(personId)}/storage`)
            if (response && response.data.success) {
                person = response.data.person
                person = person.is_active ? person : false
            }
        } catch (error) {
            handleError(error, "Person", "getPersonData", { personId: decodeURI(personId) })
        }
        return person
    }

    async function getImages(){
        let images = []
        let success = true
        for(var image of rawImages){
            let base64 = await axios.get(image.image_path, { responseType: 'arraybuffer' })
            .then((response) => {
                let b64string = btoa(
                    new Uint8Array(response.data)
                    .reduce((data, byte) => data + String.fromCharCode(byte), '')
                );
                return `data:${response.headers['content-type'].toLowerCase()};base64,${b64string}`;
                // eslint-disable-next-line no-loop-func
            }).catch((error)=>{
                success = false
            });;
            images.push(base64)
        }
        setb64Images(images)
        if (!success) {
            let status = { 
                success: false, 
                // eslint-disable-next-line no-multi-str
                message: "Ops! Está ocorrendo algum erro com o servidor e \
                            não é possivel carregar as imagens \
                            por favor tente mais tarde"
            }
            setStatus(status)
        }
    }

    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-bg-dark-grey-blue mb-0 font-weight-bold">VISUALIZAR PESSOA</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/persons">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-arrow-left mr-1" /> Voltar
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={12}>
                        <Form>
                            <Status status={status} />

                            <Form.Group>
                                <Form.Label>Nome da Pessoa</Form.Label>
                                <Form.Control type="text" value={name} disabled placeholder="Insira o Nome da Pessoa" required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Id Externo (rg, cpf)</Form.Label>
                                <Form.Control type="text" value={externalId} disabled placeholder="Insira o Id Externo" required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Empresa</Form.Label>
                                <Form.Control type="text" value={companyName} disabled />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Imagens</Form.Label>

                                <div className="d-flex flex-container">
                                    {(b64Images && b64Images.length > 0) &&
                                        b64Images.map((b64Image, index) =>
                                            <div key={rawImages[index].image_id} className="d-flex flex-column align-items-start mx-1 my-2">
                                                <Image src={b64Image} className="image_thumbnail" />
                                            </div>
                                        )
                                    }
                                </div>
                            </Form.Group>
                            <hr />
                        </Form>
                    </Col>
                </Row>
            </div>
        </MainStructure>
    )
}
