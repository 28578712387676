import React, { useState, useEffect, useContext } from 'react'

import { Navbar, Modal, Button } from 'react-bootstrap'
import { useLocation, NavLink, useHistory } from 'react-router-dom'
import { Context } from '../services/Context'

export default function Sidebar() {

    const { getAuthenticatedUser } = useContext(Context)
    const { pathname } = useLocation()
    const history = useHistory()
    const [showLogout, setShowLogout] = useState(false)
    const [user, setUser] = useState(false)

    useEffect(() => {
        loadScripts()
        if (!user) {
            getAuthenticatedUser().then(user => setUser(user))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function loadScripts() {
        let sidebar = document.querySelector("#sidebar_wrapper")
        if (sidebar) {

            sidebar.addEventListener("mouseenter", (e) => {
                e.target.classList.add("active")
            })
            sidebar.addEventListener("mouseleave", (e) => {
                e.target.classList.remove("active")
            })
        }
    }

    function confirmLogout(e) {
        e.preventDefault()
        setShowLogout(true)
    }

    function closeLogoutModal() {
        setShowLogout(false)
    }

    function logout() {
        history.push("/logout")
    }

    return (
        <>

            <div id="sidebar_wrapper" className={`bg-gryfo-dark-grey`} >
                <Navbar className="p-0 pt-2 flex-column">
                    {<div id="links_wrapper">

                        {user && user.access_level === 'admin' ?
                            <NavLink activeClassName={`${pathname === "/users" ? "side-link-active" : ""}`} className="side-link" to="/users">
                                <i className="fas fa-user"></i>
                                <span>Usuários</span>
                            </NavLink>
                            :
                            ''
                        }
                        {user && user.access_level === 'admin' && user.business_model !== 'client' ?
                            <NavLink activeClassName={`${pathname === "/companies" ? "side-link-active" : ""}`} className="side-link" to="/companies">
                                <i className="fas fa-briefcase"></i>
                                <span>Empresas</span>
                            </NavLink>
                            :
                            ''
                        }
                        {user && user.access_level === 'admin' && user.business_model !== 'client' ?
                            <NavLink activeClassName={`${pathname === "/groups" ? "side-link-active" : ""}`} className="side-link" to="/groups">
                                <i className="fas fa-city"></i>
                                <span>Grupos</span>
                            </NavLink>
                            :
                            ''
                        }

                        <NavLink activeClassName={`${pathname === "/persons" ? "side-link-active" : ""}`} className="side-link" to="/persons">
                            <i className="fas fa-id-badge"></i>
                            <span>Pessoas</span>
                        </NavLink>

                        <NavLink activeClassName={`${pathname === "/audit" ? "side-link-active" : ""}`} className="side-link" to="/audit" >
                            <i className="fas fa-balance-scale" />
                            <span>Auditoria</span>
                        </NavLink>

                        <NavLink activeClassName={`${pathname === "/services_status" ? "side-link-active" : ""}`} className="side-link" to="/services_status" >
                            <i class="fas fa-server"/>
                            <span>Status dos Serviços</span>
                        </NavLink>

                        <NavLink activeClassName="side-link-active" className="side-link" to="/logout" onClick={confirmLogout} >
                            <i className="fas fa-sign-out-alt" />
                            <span>Sair</span>
                        </NavLink>
                    </div>
                    }
                </Navbar>
            </div>
            <Modal dialogClassName="bg-gryfo-dark-blue" show={showLogout} onHide={closeLogoutModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Deseja sair?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Você tem certeza que deseja sair?</Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-gryfo-blue" onClick={closeLogoutModal}> Não </Button>
                    <Button className="btn btn-danger" onClick={logout}> Sim </Button>
                </Modal.Footer>
            </Modal>
        </>

    )
}
