import React, { useContext } from "react"
import { Route, Redirect } from "react-router-dom"
import { Context } from "../services/Context"

const PrivateRoute = ({ component: Component, accessLevel, ...otherProps }) => {

    const { isAuthenticated, isLoading, category } = useContext(Context)

    function checkAccessLevel() {
        let checkHasPermission = true
        if (accessLevel && accessLevel.length && category) {
            checkHasPermission = accessLevel.indexOf(category) !== -1 ? checkHasPermission : false
        }
        return checkHasPermission
    }

    return (
        <Route
            {...otherProps}
            render={props => (
                !isLoading &&
                (
                    (isAuthenticated && checkAccessLevel())
                        ?
                        <Component {...props} />
                        :
                        <Redirect to={otherProps.redirectTo ? otherProps.redirectTo : '/login'} />
                )

            )}
        />
    )

}


export default PrivateRoute