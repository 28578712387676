import React, { useState, useEffect, useContext } from "react"

import { Row, Col, Form, Image, Button, Modal } from "react-bootstrap"
import { Link } from "react-router-dom"

import { Context } from '../../services/Context'
import { handleError } from "../../helpers/ErrorHelpers"
import { loadCamera, stopCamera, checkHasCam, convertBase64, videoToBase64Image, checkIsAMobile } from "../../helpers/MediaHelpers"
import Status from "../../templates/Status"
import Loader from "../../templates/Loader"
import PrivateImage from "../../templates/PrivateImage"
import MainStructure from "../../structures/MainStructure"
import CompaniesFilter from "../../templates/CompaniesFilter"

export default function Recognize() {

    const { getAxios } = useContext(Context)
    const axios = getAxios()
    const [company, setCompany] = useState("")
    const [embedderType, setEmbedderType] = useState("v2")
    const [status, setStatus] = useState(false)
    const [userImage, setUserImage] = useState("")
    const [recognizedImage, setRecognizedImage] = useState("")
    const [recognizedExternalId, setRecognizedExternalId] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const [showModal, setShowModal] = useState(false)
    const [hasACamera, setHasACamera] = useState(false)
    const [cameraMode, setCameraMode] = useState("user")
    const [isAMobile] = useState(checkIsAMobile())
    const [isLoadingVideo, setIsLoadingVideo] = useState(true)

    const [isCapturing, setIsCapturing] = useState(false)
    const [imagePreview, setImagePreview] = useState(false)
    const [showModalPreview, setShowModalPreview] = useState(false)

    useEffect(() => {
        checkIfHasCamera()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (status) setStatus(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userImage])

    function closeModal() {
        let video = document.querySelector(".web_camera")
        if (video) {
            stopCamera(video)
        }
        setShowModal(false)
    }

    function closeModalPreview() {
        setShowModalPreview(false)
    }

    async function checkIfHasCamera() {
        if (await checkHasCam()) {
            setHasACamera(true)
        }
    }

    async function handleSubmit(e) {
        e.preventDefault()
        if (!userImage) return
        let status = { success: false, message: "Pessoa não encontrada" }
        let data = await recognize()
        if (data && data.image_added_by_user) {
            status = { success: true, message: "Reconhecimento efetuado com sucesso" }
            setRecognizedImage(data.image_added_by_user.image_path)
            setRecognizedExternalId(data.external_id)
        }
        setStatus(status)
    }

    async function recognize() {
        setIsLoading(true)
        let data = null
        try {
            let response = await axios.post("/person/recognize", {
                company_external_id: company['external_id'],
                images: [userImage],
                embedder_type: embedderType
            })

            if (response && response.data.message) {
                data = response.data
            }

        } catch (error) {
            handleError(error, "Recognize", "recognize", { company_id: company })
        }
        setIsLoading(false)
        return data
    }

    function checkImageIsValid(file) {
        let check = true
        if (!file) {
            setStatus({ success: false, message: "Arquivo inválido" })
            check = false
        } else if (!file.type.includes("png") && !file.type.includes("jpg") && !file.type.includes("jpeg")) {
            setStatus({ success: false, message: "A imagem precisa estar em um dos seguintes formatos: JPG, PNG, JPEG" })
            check = false
        }
        return check
    }

    async function addImage(file, setImageFunction = setUserImage) {
        if (!checkImageIsValid(file)) return
        let image = await convertBase64(file)
        if (image) setImageFunction(image)
        else setStatus({ success: false, message: "Ocorreu um erro ao pré-processar essa imagem" })
        clearInput(document.querySelector("#input_upload"))
    }

    function clearInput(input) {
        if (input) {
            input.value = ""
        }
    }

    async function handleLoadCamera() {
        let video = document.querySelector(".web_camera")
        if (!video) return
        setIsLoadingVideo(true)
        await loadCamera(video)
        setIsLoadingVideo(false)
    }

    async function getBase64FromWebcam() {
        const video = document.querySelector(".web_camera")
        const maxTime = 20000
        let time = 0
        const intervalNumber = 400
        return await new Promise((resolve) => {
            let interval = setInterval(async () => {
                let image = videoToBase64Image(video)
                if (image) {
                    clearInterval(interval)
                    resolve(image)
                } else if (time >= maxTime) {
                    clearInterval(interval)
                    resolve(false)
                }
                time += intervalNumber
            }, intervalNumber)
        })
    }

    async function capture() {
        setIsCapturing(true)
        let base64 = await getBase64FromWebcam()
        if (base64) {
            closeModal()
            setImagePreview(base64)
            setShowModalPreview(true)
        }
        setIsCapturing(false)
    }

    function confirmPreview() {
        let base64 = imagePreview
        setUserImage(base64)
        setShowModalPreview(false)
        setImagePreview(false)
    }

    function cancelPreview() {
        setShowModalPreview(false)
        setShowModal(true)
    }

    function clearForm() {
        setRecognizedExternalId("")
        setRecognizedImage("")
        setUserImage("")
        setCompany("")
    }

    async function handleChangeCameraMode() {
        let video = document.querySelector(".web_camera")
        if (!video) return
        setIsLoadingVideo(true)
        stopCamera(video)
        let newMode = cameraMode === "user" ? "environment" : "user"
        setCameraMode(newMode)
        await loadCamera(video, { facingMode: newMode })
        setIsLoadingVideo(false)
    }

    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">RECONHECER PESSOA</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/persons">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-arrow-left mr-1" /> Voltar
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={12}>
                        <Form onSubmit={handleSubmit}>
                            <Status status={status} />
                            <Form.Group>
                                <Form.Label>Empresa</Form.Label>
                                <CompaniesFilter 
                                    onSelectCompany={setCompany} 
                                    companyMode="client"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Versão do Reconhecedor</Form.Label>
                                <Form.Control as="select" value={embedderType} onChange={e => setEmbedderType(e.target.value)} required>
                                    <option key={"v2"} value={"v2"}> v2 </option>
                                    <option key={"v3"} value={"v3"}> v3 </option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Upload de Imagem</Form.Label>
                                <div className="d-flex flex-row">
                                    <Form.File
                                        id="input_upload"
                                        label="Clique para fazer upload"
                                        onChange={e => addImage(e.target.files[0])}
                                        className="mr-2"
                                        disabled={status.success}
                                        custom
                                    />
                                    {hasACamera ?
                                        <Button className="btn btn-gryfo-blue height-input" onClick={() => setShowModal(true)}><i className="fas fa-camera" /></Button>
                                        :
                                        <Button className="btn btn-gryfo-blue height-input" disabled><i className="fas fa-camera" /></Button>
                                    }

                                </div>
                                <div className="d-flex flex-container">
                                    {userImage && (
                                        <div id="input_photo" className="d-flex flex-column align-items-start mx-1 my-2">
                                            <i className="fas fa-trash image_button text-white bg-danger" hidden={status.success} onClick={() => setUserImage("")} />
                                            <Image src={userImage} className="image_thumbnail" />
                                        </div>
                                    )}

                                </div>
                            </Form.Group>
                            {recognizedImage &&
                                <>
                                    <hr />
                                    <h1>Pessoa Reconhecida</h1>
                                    <Form.Group>
                                        <h5>{recognizedExternalId}</h5>
                                        <div id="output_photo" className="d-flex flex-column align-items-start mx-1 my-2">
                                            <PrivateImage uri={recognizedImage} className="image_thumbnail" />
                                        </div>
                                    </Form.Group>
                                </>
                            }

                            <hr />
                            {isLoading ?
                                <Button className="btn btn-gryfo-blue" disabled type="button">
                                    <i className="fas fa-spin fa-spinner" />&nbsp;
                                    Carregando
                                </Button>
                                :
                                <>
                                    <Button className="btn btn-gryfo-blue" type="submit" hidden={status.success}>
                                        Reconhecer
                                    </Button>
                                    <Button className="btn btn-gryfo-blue" type="button" onClick={clearForm} hidden={!status.success}>
                                        Reconhecer Novamente
                                    </Button>
                                </>
                            }
                        </Form>
                    </Col>
                </Row>
            </div>
            <canvas id="imgCanvas" />

            <Modal
                dialogClassName={`modal-full-screen ${isCapturing ? "detecting" : ""}`}
                show={showModal}
                onEntered={handleLoadCamera}
                onHide={closeModal}
            >
                {isLoadingVideo && <Loader />}
                <div className="modal-video-header">
                    <Button className="btn btn-danger m-3" onClick={closeModal}>
                        <i className="fas fa-times" />
                    </Button>
                </div>

                <video autoPlay={true} className="col-12 web_camera"></video>
                {!isLoadingVideo &&
                    <div className="modal-video-footer">
                        {(isAMobile) &&
                            <Button className="btn btn-gryfo-blue m-2" onClick={handleChangeCameraMode}> <i className="fas fa-redo" /> Inverter Camera</Button>
                        }
                        {isCapturing ?
                            <Button className="btn btn-gryfo-blue m-3 disabled" > <i className="fas fa-spin fa-spinner" /> Processando</Button>
                            :
                            <Button className="btn btn-gryfo-blue m-3" onClick={capture}> <i className="fas fa-vector-square" /> Capturar</Button>
                        }
                    </div>
                }

            </Modal>
            <Modal
                dialogClassName={`modal-full-screen ${isCapturing ? "detecting" : ""}`}
                animation={false}
                show={showModalPreview}
                onHide={closeModalPreview}
            >
                <div className="modal-video-header">
                    <Button className="btn btn-danger m-3" onClick={closeModalPreview}>
                        <i className="fas fa-times" />
                    </Button>
                </div>

                {(imagePreview) && <Image src={imagePreview} className="web_camera_preview" />}
                <div className="modal-video-footer">
                    <Button className="btn btn-danger m-3" onClick={cancelPreview}> <i className="fas fa-times" /> Cancelar</Button>
                    <Button className="btn btn-gryfo-blue m-3" onClick={confirmPreview}> <i className="fas fa-check" /> Confirmar</Button>
                </div>

            </Modal>
        </MainStructure >
    )
}
