import React, { useState, useContext, useEffect } from "react"

import { Row, Col, Form, Button } from "react-bootstrap"
import { Link, useHistory, useParams } from "react-router-dom"

import { Context } from '../../services/Context'
import { handleError } from "../../helpers/ErrorHelpers"
import MainStructure from "../../structures/MainStructure"
import Status from "../../templates/Status"

export default function UpdateUser() {
    const { getAxios, getAuthenticatedUser } = useContext(Context)
    const axios = getAxios()
    const history = useHistory()
    const { updateCompanyId } = useParams()

    const [user, setUser] = useState({})
    const [companyName, setCompanyName] = useState("")
    const [companyExternalId, setCompanyExternalId] = useState("")
    const [userbusinessModel, setUserbusinessModel] = useState("")
    const [businessModel, setbusinessModel] = useState("")
    const [partnerName, setPartnerName] = useState("")
    const [status, setStatus] = useState(false)
    const [gryfoWhiteLabel, setGryfoWhiteLabel] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (status) setStatus(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyName, businessModel])

    useEffect(() => {
        async function checkAndGetUpdateCompanyData() {

            const user = await getAuthenticatedUser()
            setUserbusinessModel(user.business_model)
            if (user) setUser(user)

            if (user.access_level !== 'admin' || (user.business_model !== 'partner' && user.business_model !== 'gryfo')) {
                history.replace("/")
                return
            }

            if (!updateCompanyId) {
                history.replace("/companies")
                return
            }
            getCompanyData().then(company => {
                if (!company) {
                    history.replace("/companies")
                    return
                }
                let business = 'gryfo'
                if (company.business_model !== 'gryfo') {
                    business = company.business_model === 'partner' ? 'Parceiro' : 'Cliente'
                }
                setCompanyName(company.company_name)
                setCompanyExternalId(company.external_id)
                setbusinessModel(business)
                setPartnerName(company.partner_name)
                console.log(company)
                setGryfoWhiteLabel(company.gryfo_white_label)
            })

        }

        checkAndGetUpdateCompanyData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleCheck(e){
        setGryfoWhiteLabel(e.target.checked)
    }

    async function getCompanyData() {
        let company = false
        try {
            let response = await axios.get(`/company/${updateCompanyId}/storage`)

            if (response && response.data) {
                company = response.data.company
            }
        } catch (error) {
            handleError(error, "UpdateCompany", "getCompanyData", { updateCompanyId: updateCompanyId })
        }
        return company
    }

    function checkFormIsValid() {
        if (!companyName) return false

        return true
    }

    async function handleSubmit(e) {
        e.preventDefault()

        if (!checkFormIsValid()) return

        let status = await postCompany()

        setStatus(status)
    }

    async function postCompany() {
        setIsLoading(true)
        let status = { success: false, message: "Não foi possível atualizar a Empresa" }
        try {
            let data = {
                company_name: companyName,
                external_id: companyExternalId,
                gryfo_white_label: gryfoWhiteLabel
            }
            let response = await axios.post(`/company/update/${updateCompanyId}/storage`, data)
            console.log(response)
            if (response && response.data) {
                status = response.data
            }
        } catch (error) {
            handleError(error, "UpdateCompany", "postCompany", updateCompanyId)
        }
        setIsLoading(false)
        return status
    }

    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">EDITAR EMPRESA</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/companies">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-arrow-left mr-1" /> Voltar
                        </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Status status={status} />
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>Nome da empresa</Form.Label>
                                <Form.Control type="text" value={companyName} onChange={e => setCompanyName(e.target.value)} placeholder="Insira o nome da empresa" required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>ID Externo </Form.Label>
                                <Form.Control type="text" onChange={e => setCompanyExternalId(e.target.value)} value={companyExternalId}/>
                            </Form.Group>
                            {user && user.business_model === 'gryfo' ? <>
                            <Form.Group>
                                <Form.Label>Modelo de Negócio</Form.Label>
                                <Form.Control disabled type="text" value={businessModel} />
                            </Form.Group>
                            {(partnerName && businessModel === 'Cliente') ?
                                <>
                                    <Form.Group>
                                        <Form.Label>Empresa Parceira</Form.Label>
                                        <Form.Control disabled type="text" value={partnerName} />
                                    </Form.Group>
                                    <hr />
                                </>
                                :
                                <hr />
                            }</>:''}
                            {businessModel === 'Parceiro' && user.business_model === 'gryfo' ?
                            <>
                            <Form.Label>Gryfo White Label</Form.Label>
                                <Form.Check type="checkbox" checked={gryfoWhiteLabel} value={gryfoWhiteLabel} onChange={handleCheck} disabled={status.success} />
                            </>: <></>}
                            <hr />
                            {isLoading ?
                                <Button className="btn btn-gryfo-blue" disabled type="button">
                                    <i className="fas fa-spin fa-spinner" />&nbsp;
                                Carregando
                            </Button>
                                :
                                <Button className="btn btn-gryfo-blue" type="submit">Atualizar</Button>
                            }
                        </Col>
                    </Form.Row>
                </Form>
            </div>
        </MainStructure>
    )
}