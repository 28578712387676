import React from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap'


import gryfo from '../../../assets/images/GRYFO.png'
import robot from '../../../assets/images/robot-solid.svg'

export default function Error404() {

    return (
        <div>
            <Container>
                <Row className="my-5 py-5 mx-1 error_row">
                    <Col xs={12} md={6}>
                        <Row>
                            <Col xs={12} md={6}>
                                <Image src={gryfo} fluid />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-2 mt-3">
                                <h3 className=" mb-0">Erro 404</h3>
                                <span className="lead">Tela não encontrada</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2 ml-2 ">
                                <p>O caminho digitado não foi encontrado.
                                Para voltar a tela inicial clique no botão abaixo.</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="ml-2">
                                <Button
                                    onClick={() => window.location.replace('/')}
                                    className="btn btn-gryfo-blue col-12 col-md-6">
                                    <i className="fas fa-home " /> Voltar a tela inicial
                                </Button>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={12} md={6} className="d-flex justify-content-center justify-content-md-end">
                        <Image src={robot} className="robot_error_image mt-3 mt-md-0" />
                    </Col>
                </Row>

            </Container>
        </div >
    )
}

