import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import PrivateRoute from "./components/templates/PrivateRoute"
import Context from './components/services/Context'

import Login from "./components/pages/authentication/Login"
import Logout from "./components/pages/authentication/Logout"

import Home from "./components/pages/home/Home"

import UserList from "./components/pages/user/UserList"
import AddUser from "./components/pages/user/AddUser"
import UpdateUser from "./components/pages/user/UpdateUser"
import UserAccount from "./components/pages/user/UserAccount"

import CompanyList from "./components/pages/company/CompanyList"
import AddCompany from "./components/pages/company/AddCompany"
import UpdateCompany from "./components/pages/company/UpdateCompany"

import AddGroup from "./components/pages/groups/AddGroup"
import UpdateGroup from "./components/pages/groups/UpdateGroup"
import GroupList from "./components/pages/groups/GroupList"

import Person from './components/pages/persons/Person'
import PersonsList from "./components/pages/persons/PersonsList"
import AddPerson from "./components/pages/persons/AddPerson"
import UpdatePerson from "./components/pages/persons/UpdatePerson"

import Audit from './components/pages/audit/Audit'

import Recognize from './components/pages/recognize/Recognize'

import ServicesStatus from './components/pages/services_status/ServicesStatus'

import Error404 from "./components/pages/error/Error404"
import Error500 from "./components/pages/error/Error500"
import Error from "./components/pages/error/Error"

export default function Router(props) {

    return (
        <Context>
            <Switch>
                <PrivateRoute exact path="/" component={Home} />
                <Route path="/login" component={Login} />
                <Route path="/logout" component={Logout} />
                <Route path="/error/404" component={Error404} />
                <Route path="/error/500" component={Error500} />
                <Route path="/error" component={Error} />

                <PrivateRoute path="/companies" component={CompanyList} />
                <PrivateRoute path="/company/add" component={AddCompany} />
                <PrivateRoute path="/company/update/:updateCompanyId" component={UpdateCompany} />

                <PrivateRoute path="/group/add" component={AddGroup} />
                <PrivateRoute path="/group/update/:updateGroupId" component={UpdateGroup} />
                <PrivateRoute path="/groups" component={GroupList} />

                <PrivateRoute path="/users" component={UserList} />
                <PrivateRoute path="/user/add" component={AddUser} />
                <PrivateRoute path="/user/update/:updateUserId" component={UpdateUser} />
                <PrivateRoute path="/user/account" component={UserAccount} />

                <PrivateRoute path="/persons" component={PersonsList} />
                <PrivateRoute path="/person/add" component={AddPerson} />
                <PrivateRoute path="/person/update/:updatePersonId" component={UpdatePerson} />
                <PrivateRoute path="/person/:personId" component={Person} />

                <PrivateRoute path="/audit" component={Audit} />

                <PrivateRoute path="/recognize" component={Recognize} />
                
                <PrivateRoute path="/services_status" component={ServicesStatus} />

                <Redirect from="*" to="/error/404" />
            </Switch>
        </Context>
    )
}

