import React, { useState, useEffect, useContext } from "react"

import { Row, Col, Form, Button } from "react-bootstrap"
import { Link } from "react-router-dom"

import { Context } from '../../services/Context'
import Service from "../../templates/Service"
import MainStructure from "../../structures/MainStructure"

export default function Recognize() {

    const { getAxios } = useContext(Context)
    const axios = getAxios()
    const [statusSyncFaces, setStatusSyncFaces] = useState( { status: 'locked', message: "Updating SyncFaces Status", details: []})
    const [statusServerless, setStatusServerless] = useState( { status: 'locked', message: "Updating Serverless Status", details: []})
    const [statusVPCServerless, setStatusVPCServerless] = useState( {status: 'locked', message: "Updating VPC Serverless Status", details: []})
    const [isLoading, setIsLoading] = useState(false)
    let userbusinessModel = null
    const { getAuthenticatedUser } = useContext(Context)

    useEffect(() => {
        async function getAndSetUser() {
            const user = await getAuthenticatedUser()
            userbusinessModel = user.business_model
        }


        const interval = setInterval(() => {getAndSetUser();handleSubmit(null)}, 5000);
        return () => {
          clearInterval(interval);
        };
      }, []);


    function handleStatusSyncFaces(data){
        let status = { status: 'not_ok', message: "SyncFaces NOT OK", details: [], userbusinessModel: userbusinessModel}
        if(data != null){
            let details = data.details.sync_faces
            if(data.status.sync_faces == 'ok'){
                status = { status: 'ok', message: "SyncFaces OK", details: details, userbusinessModel: userbusinessModel}
            }
            else if(data.status.sync_faces == 'locked'){
                status = { status: 'locked', message: "Updating SyncFaces Status", details: details, userbusinessModel: userbusinessModel }
            }
            else{
                status = { status: 'not_ok', message: "SyncFaces NOT OK", details: details, userbusinessModel: userbusinessModel }
            }
        }
        return status
    }

    function handleStatusVPCServerless(data){
        let status = { status: 'not_ok', message: "VPC Serverless NOT OK", details: [], userbusinessModel: userbusinessModel}
        if(data != null){
            let details = data.details.vpc_serverless
            if(data.status.vpc_serverless == 'ok'){
                console.log(details[0])
                status = { status: 'ok', message: "VPC Serverless OK", details: details, userbusinessModel: userbusinessModel}
            }
            else if(data.status.vpc_serverless == 'locked'){
                status = { status: 'locked', message: "Updating VPC Serverless Status", details: details, userbusinessModel: userbusinessModel}
            }
            else{
                status = { status: 'not_ok', message: "VPC Serverless NOT OK", details: details, userbusinessModel: userbusinessModel}
            }
        }
        return status
    }


    function handleStatusServerless(data){
        let status = { status: 'not_ok', message: "Serverless NOT OK", details: [], userbusinessModel: userbusinessModel}
        if(data != null){
            let details = data.details.serverless
            if(data.status.serverless == 'ok'){
                status = { status: 'ok', message: "Serverless OK", details: details, userbusinessModel: userbusinessModel}
            }
            else if(data.status.serverless == 'locked'){
                status = { status: 'locked', message: "Updating Serverless Status", details: details, userbusinessModel: userbusinessModel}
            }
            else{
                status = { status: 'not_ok', message: "Serverless NOT OK", details: details, userbusinessModel: userbusinessModel}
            }
        }
        return status
    }

    async function handleSubmit(e) {
        if(e != null){
            e.preventDefault()
        }

        let data = await servicesStatus()

        let statusSyncFaces = handleStatusSyncFaces(data)
        let statusVPCServerless = handleStatusVPCServerless(data)
        let statusServerless = handleStatusServerless(data)
        
        setStatusSyncFaces(statusSyncFaces)
        setStatusVPCServerless(statusVPCServerless)
        setStatusServerless(statusServerless)
    }

    async function servicesStatus() {
        setIsLoading(true)
        let data = null
        try {
            
            let response = await axios.post("/services_status", {
                details: true,
            })
            //console.log(response)

            if (response && response.data.success) {
                data = response.data
            }

        } catch (error) {
            console.log(error)
        }
        setIsLoading(false)
        return data
    }

    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">STATUS DOS SERVIÇOS</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/persons">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-arrow-left mr-1" /> Voltar
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={12}>
                        <Form onSubmit={handleSubmit}>
                            <Service status={statusSyncFaces} />
                            <hr/>
                            <Service status={statusVPCServerless} />
                            <hr/>
                            <Service status={statusServerless} />
                            <hr/>
                        </Form>
                    </Col>
                </Row>
            </div>

        </MainStructure >
    )
}
