
export const loadCamera = async (video, videoObject = { facingMode: 'user' }) => {

    //As opções abaixo são necessárias para o funcionamento correto no iOS
    video.setAttribute('autoplay', '')
    video.setAttribute('muted', '')
    video.setAttribute('playsinline', '')

    //Verifica se o navegador pode capturar mídia
    if (navigator.mediaDevices.getUserMedia) {
        await navigator.mediaDevices.getUserMedia({ audio: false, video: videoObject })
            .then(function (stream) {
                //Definir o elemento vídeo a carregar o capturado pela webcam
                video.srcObject = stream
            })
            .catch(function (error) {
                alert("Não foi possível capturar a sua câmera.")
            })
    }
}

export const stopCamera = (video) => {
    const stream = video.srcObject
    if (!stream) return
    const tracks = stream.getTracks()

    tracks.forEach(function (track) {
        track.stop()
    })

    video.srcObject = null
}

export const checkVideoHasStream = (video) => {
    let check = false
    if (video) {
        check = video.srcObject ? true : check
    }
    return check
}

export const takeSnapShot = (video) => {

    //Criando um canvas que vai guardar a imagem temporariamente
    var canvas = document.createElement('canvas')
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    var ctx = canvas.getContext('2d')

    //Desenhando e convertendo as dimensões
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

    //Criando o JPG
    var dataURI = canvas.toDataURL('image/jpeg') //O resultado é um BASE64 de uma imagem.
    return dataURI
}

export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
    })

}

export function videoToBase64Image(video) {
    if (!video) return false
    const canvas = document.createElement("canvas")
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight
    canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
    const dataURL = canvas.toDataURL()
    return dataURL
}

export async function resizeBase64(base64, resizeNumber = 480) {

    return new Promise((resolve, reject) => {

        var img = document.createElement('img')
        img.src = base64

        img.onload = function () {
            let width = img.width
            let height = img.height
            let wantedWidth = resizeNumber
            let wantedHeight = resizeNumber

            if (width !== resizeNumber && height !== resizeNumber) {
                let greaterDimension = width > height ? width : height
                let aspect = resizeNumber / greaterDimension
                wantedWidth = aspect * width
                wantedHeight = aspect * height

            }

            let canvas = document.createElement('canvas')
            canvas.width = wantedWidth
            canvas.height = wantedHeight


            let ctx = canvas.getContext('2d')

            ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight)

            let dataURI = canvas.toDataURL()

            resolve(dataURI)
        }

    })

}

export async function resizeCollectionOfBase64Images(base64Collection, resizeValue = 480) {
    let newCollection = {}
    for (let [key, value] of Object.entries(base64Collection)) {
        newCollection[key] = await resizeBase64(value, resizeValue)
    }
    return newCollection
}

export const checkHasCam = async () => {
    let check = false
    //Verifica se o navegador pode capturar mídia
    if (navigator.mediaDevices.getUserMedia) {
        try {
            let stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { facingMode: "user" } })
            if (stream) {
                check = true
                const track = stream.getVideoTracks()[0]
                track.stop()
            }
        } catch (error) {
            console.log("Não há camera", error)
        }
    }
    return check
}

export const checkIsAMobile = () => {
    let check = false
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        check = true
    }
    return check
}