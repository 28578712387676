import React, { useContext, useState } from "react"
import moment from 'moment'
import { CSVLink } from "react-csv";
import { Modal, Col, Button, Form } from "react-bootstrap"
import { Context } from '../../services/Context'
import CompaniesFilter from "../../templates/CompaniesFilter"
import DateFilter from "../../templates/DateFIlter"
import { handleError } from "../../helpers/ErrorHelpers"

export default function ModalExportAudits({ isVisible, setIsVisible, user }) {

    const { getAxios } = useContext(Context)
    const axios = getAxios()
    const [isLoading, setIsLoading] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState("all")
    const [companyPartner, setCompanyPartner] = useState(false)
    const [companyClient, setCompanyClient] = useState(false)
    const [startDate, setStartDate] = useState('');
    const [csvData, setCsvData] = useState(false);
    const [endDate, setEndDate] = useState(moment().endOf('day').toDate());


    async function handleExport(e) {
        e.preventDefault()
        if(!companyClient){
            window.alert("Cliente não pode estar vazio")
            return;
        } 

        if(!startDate){
            window.alert("Data de início não pode estar vazia")
            return;
        } 
        const payload = {
            company_id: companyClient['_id'],
            category: selectedCategory,
        }

        if (startDate) payload['startDate'] = Math.floor(startDate.getTime() / 1000)
        if (endDate) payload['endDate'] = Math.floor(endDate.getTime() / 1000)
        try {
            let response = await axios.post("/auditions/export", payload)
            if (response && response.data) {
                setCsvData(response.data)
            } else {
                window.alert("Não foi possível exportar esse audit")
            }

        } catch (error) {
            handleError(error, "Audit", "handleExport", payload)
        }
    }

    return (
        <Modal
            show={isVisible}
            onHide={() => setIsVisible(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton={!isLoading}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Exportar auditorias reportadas
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleExport}>
                    <Form.Row>
                        <Form.Group as={Col} xs={12} md className="my-2 align-self-center">
                            <DateFilter
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                label="Início"
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} md className="my-2 align-self-center">
                            <DateFilter
                                startDate={endDate}
                                setStartDate={setEndDate}
                                label="Fim"
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} xs={12} md className="my-2 align-self-center">
                            <Form.Control as="select" onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory} required={true}>
                                <option value={'all'} key={'default-category-select'}>Todas Categorias</option>
                                <option value={'FRAUD_EVIDENCE'} key={'fraud-evidence-category-select'}>Indicio de Fraude</option>
                            </Form.Control>
                        </Form.Group>
                        {(user && user.business_model === "gryfo") &&
                            <CompaniesFilter
                                className="form-group my-2 col-12 col-md align-self-center"
                                onSelectCompany={setCompanyPartner}
                                companyMode="partner"
                                placeholder="Parceiro"
                                required={true}
                            />}
                        {(user && user.business_model !== "client") &&
                            <CompaniesFilter
                                className="form-group my-2 col-12 col-md align-self-center"
                                onSelectCompany={setCompanyClient}
                                companyMode="client"
                                partnerId={
                                    (companyPartner) ?
                                    companyPartner['_id']
                                    :
                                    null
                                }
                                placeholder="Cliente"
                                disabled={!companyPartner}
                                required={true}
                            />}
                        <Button variant="primary" className="btn btn-gryfo-blue my-2" type="submit" style={{ width: "125px" }}>
                            <i className="fas fa-file-csv mr-2" style={{ fontSize: "14pt" }} />Exportar
                        </Button>
                    </Form.Row>
                </Form>
                {csvData &&
                    <CSVLink filename={"audit.csv"} data={csvData}>Clique para baixar</CSVLink>}
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-gryfo-blue" disabled={isLoading} onClick={() => setIsVisible(false)}>Sair</Button>
            </Modal.Footer>
        </Modal>

    )


}