import React, { useState, useEffect, useContext } from "react"

import $ from 'jquery'
import { Form, Row, Col, Button, InputGroup, FormControl } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"

import { Context } from '../../services/Context'
import MainStructure from "../../structures/MainStructure"
import Loader from '../../templates/Loader'
import { handleError } from "../../helpers/ErrorHelpers"
import datatablePt from "../../../assets/lang/datatablePt.json"
import BusinessModelFilter from "../../templates/BusinessModelFilter"
import IsActiveFilter from "../../templates/IsActiveFilter"
import ModalCompanyActivation from "./ModalCompanyActivation"

$.DataTable = require("datatables.net")

export default function CompanyList() {
    const { getAxios } = useContext(Context)
    const axios = getAxios()
    const history = useHistory()
    const [companies, setCompanies] = useState(false)
    const [isActive, setIsActive] = useState(true)
    const [businessModel, setBusinessModel] = useState(false)
    const [companyActivationArrayData, setCompanyActivationArrayData] = useState(null)

    useEffect(() => {
        getAndSetCompanies()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, businessModel])

    useEffect(() => {
        loadDataTable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companies])

    async function getAndSetCompanies() {
        let companies = await getCompanies()
        if (companies) {
            setCompanies(companies)
        }
    }

    async function getCompanies() {
        let companies = false
        try {
            let payload = {
                is_active: isActive
            }
            let response = await axios.post('/companies', payload)
            if (response && response.data) {
                companies = response.data.companies
                companies = filterCompanies(companies)
            }
        } catch (error) {
            handleError(error, "CompanyList", "getAndSetCompanies")
        }
        return companies
    }

    function filterCompanies(companies) {
        if (businessModel) {
            companies = companies.filter((company) => company.business_model === businessModel)
        }
        return companies
    }

    function clearDataTable() {
        $('#companies-table').DataTable().clear()
        $('#companies-table').DataTable().destroy()
    }

    function loadDataTable() {
        if ($('#companies-table').DataTable()) {
            clearDataTable()
        }
        let data = []
        if (companies && companies.length > 0) {
            companies.forEach(item => {
                data.push([item.company_name, item._id])
            })
            var table = $('#companies-table').DataTable({
                dom: 'Bitp',
                "language": datatablePt,
                data: data,
                "columnDefs": [
                    {
                        "data": null,
                        "render": function (data, type, row, meta) {
                            let companyName = row[0]
                            return `<div class="text-center" style="font-size: 10pt;">
                                        <i id="edit-btn-`+ companyName + `" name="edit-btn" class="m-1 h4 text-gryfo-dark-yellow cursor-pointer fas fa-edit" data-toggle="tooltip" data-placement="left" title="Editar Empresa"></i>`
                                + (isActive ?
                                    `<i id="deact-btn-` + companyName + `" name="deact-btn" class="m-1 h4 cursor-pointer fas fa-ban" data-toggle="tooltip" data-placement="left" title="Desativar Empresa"></i>` :
                                    `<i id="act-btn-` + companyName + `" name="act-btn" class="m-1 h4 cursor-pointer fas fa-handshake text-success" data-toggle="tooltip" data-placement="left" title="Reativar Empresa"></i>`
                                ) + `<i id="rm-btn-` + companyName + `" name="rm-btn" class="m-1 h4 text-danger cursor-pointer fas fa-trash" data-toggle="tooltip" data-placement="left" title="Remover Empresa"></i>
                                    </div>`
                        },
                        "targets": -1
                    }
                ]
            })

            $('#search-companies').on('keyup', function () {
                table.search($('#search-companies').val()).draw();
            });

            $('body').on('click', "i[name='edit-btn']", function () {
                var data = table.row($(this).parents('tr')).data();
                if (!data || data.length === 0) return
                history.push('/company/update/' + data[1])
            });

            $('body').on('click', "i[name='deact-btn']", async function () {
                var data = table.row($(this).parents('tr')).data();
                if (!data || data.length === 0) return
                setCompanyActivationArrayData(data)

            });

            $('body').on('click', "i[name='act-btn']", async function () {
                var data = table.row($(this).parents('tr')).data();
                if (!data || data.length === 0) return
                setCompanyActivationArrayData(data)
            });

            $('body').on('click', "i[name='rm-btn']", async function () {
                var data = table.row($(this).parents('tr')).data();
                if (!data || data.length === 0) return

                if (!window.confirm("Deseja mesmo remover esta empresa?")) return
                let response = await axios.delete('/company/delete/' + data[1] + '/storage')
                if (response && response.status === 200) {
                    response.data.success ? getAndSetCompanies() : alert(response.data.message)
                }
            });
        }
    }


    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">EMPRESA</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/company/add">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-plus mr-1" /> Cadastrar Empresa
                        </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <Loader show={companies}>
                    <Row>
                        <Col>
                            <Form>
                                <Form.Row>
                                    <InputGroup className="form-group my-2 col-12 col-md">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text ><i className="fas fa-search"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl id="search-companies" placeholder="Procurar Empresa" />
                                    </InputGroup>
                                    <BusinessModelFilter onSelectBusinessModel={setBusinessModel} />
                                    <IsActiveFilter onChange={setIsActive} />
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>

                    {(companies && companies.length > 0) ?
                        <div className="table-responsive">
                            <table id="companies-table" className="table table-striped table-bordered" width="100%">
                                <thead className="bg-gryfo-blue text-white">
                                    <tr>
                                        <th>NOME</th>
                                        <th className="text-center" style={{ maxWidth: "300px" }}>AÇÕES</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        :
                        'Não há empresas a serem exibidas'
                    }
                </Loader>
            </div>
            <ModalCompanyActivation
                getAndSetCompanies={getAndSetCompanies}
                companyActivationArrayData={companyActivationArrayData}
                setCompanyActivationArrayData={setCompanyActivationArrayData}
                activate={!isActive}
            />
        </MainStructure>
    )
}