import { useEffect, useContext } from "react"

import { useHistory } from "react-router-dom"

import { Context } from "../../services/Context"

export default function Logout() {

    let history = useHistory()

    const { doLogout } = useContext(Context)

    useEffect(() => {
        doLogout()
        history.push("/login")
    }, [doLogout, history])

    return false
}