import React from 'react'
import ReactDOM from 'react-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/index.css'
import './assets/slider.css'

import '@fortawesome/fontawesome-free/css/all.min.css'

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from './App'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://3bc431a474ae482dbb234efa5d9cf20c@o463715.ingest.sentry.io/5806279",
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    release: "sync-faces@" + process.env.npm_package_version,
  });
}


ReactDOM.render(
  <App />,
  document.getElementById('root')
);