import React, { useState, useContext, useEffect } from "react"

import { Row, Col, Form, Button } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"

import { Context } from '../../services/Context'
import { handleError } from "../../helpers/ErrorHelpers"
import MainStructure from "../../structures/MainStructure"
import CompaniesFilter from "../../templates/CompaniesFilter"
import Status from "../../templates/Status"

export default function AddUser() {

    const { getAxios, getAuthenticatedUser } = useContext(Context)
    const axios = getAxios()
    const history = useHistory()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [rePassword, setRePassword] = useState("")
    const [accessLevel, setAccessLevel] = useState("admin")
    const [company, setCompany] = useState(false)
    const [userBusinessModel, setUserBusinessModel] = useState("")
    const [status, setStatus] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function getLoggedUser() {
            const user = await getAuthenticatedUser()
            if (user.access_level !== 'admin') {
                history.replace("/")
            }
            setUserBusinessModel(user.business_model)
        }
        getLoggedUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        if (status) setStatus(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [username, password, rePassword, accessLevel])

    async function handleSubmit(e) {
        e.preventDefault()

        if (!checkFormIsValid()) return

        let status = await postUser()

        if (status.success) {
            status.message = "Usuário criado com sucesso!"
            setUsername("")
            setPassword("")
            setRePassword("")
            setAccessLevel("admin")
        }

        setStatus(status)
    }

    function checkFormIsValid() {
        if (!username || !password || !rePassword) return false

        if (password !== rePassword) {
            setStatus({ success: false, message: "As senhas precisam ser iguais" })
            return false
        }
        if (userBusinessModel !== 'client' && !company) return false
        return true
    }

    async function postUser() {
        setIsLoading(true)
        let status = { success: false, message: "Não foi possível criar o Usuário" }
        try {

            let data = {
                username: username,
                password: password,
                access_level: accessLevel
            }
            if (userBusinessModel !== 'client') {
                data['company_id'] = company['_id']
            }
            let response = await axios.post("/user/add", data)
            if (response && response.data.success) {
                status = response.data
            }
        } catch (error) {
            handleError(error, "AddUser", "postUser", username)
        }
        setIsLoading(false)
        return status
    }

    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">ADICIONAR UM USUÁRIO</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/users">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-arrow-left mr-1" /> Voltar
                        </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Status status={status} />
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>Nome de Usuário</Form.Label>
                                <Form.Control type="text" value={username} onChange={e => setUsername(e.target.value)} placeholder="Insira o nome de usuário" required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Senha</Form.Label>
                                <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Insira a sua senha" required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Senha</Form.Label>
                                <Form.Control type="password" value={rePassword} onChange={e => setRePassword(e.target.value)} placeholder="Repita a senha" required />
                            </Form.Group>

                            <Form.Group hidden={userBusinessModel === 'client'}>
                                <Form.Label>Parceiro</Form.Label>
                                <CompaniesFilter
                                    onSelectCompany={setCompany}
                                    placeholder={'Seleciona a Empresa'}
                                    companyMode='partner' 
                                />
                            </Form.Group>
                            {/* <Form.Group hidden={userBusinessModel === 'client'}>
                                <Form.Label>Empresa a que pertence</Form.Label>
                                <Form.Control required={userBusinessModel !== 'client'} custom as="select" value={company} onChange={e => setCompany(e.target.value)}>
                                    <option disabled value={''} key={'default-module-select'}>Selecione a Empresa</option>
                                    {(companies && companies.length > 0) && companies.map(company =>
                                        <option key={company._id} value={company._id}> {company.company_name} </option>
                                    )}
                                </Form.Control>
                            </Form.Group> */}
                            {/* <Form.Group>
                                <Form.Label>Nível de Acesso</Form.Label>
                                <Form.Check
                                    checked={accessLevel === "admin"}
                                    onChange={e => setAccessLevel("admin")}
                                    type="radio"
                                    label="Administrador"
                                    name="accessLevel"
                                    id="admin"
                                />
                                <Form.Check
                                    checked={accessLevel === "operational"}
                                    onChange={e => setAccessLevel("operational")}
                                    type="radio"
                                    label="Operador"
                                    name="accessLevel"
                                    id="operational"
                                />
                            </Form.Group> */}
                            <hr />
                            {isLoading ?
                                <Button className="btn btn-gryfo-blue" disabled type="button">
                                    <i className="fas fa-spin fa-spinner" />&nbsp;
                                Carregando
                            </Button>
                                :
                                <Button className="btn btn-gryfo-blue" type="submit">Cadastrar</Button>
                            }
                        </Col>
                    </Form.Row>
                </Form>
            </div>
        </MainStructure>
    )
}