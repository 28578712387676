import React, { useState, useEffect, useContext, Fragment } from "react"
import { Form, Row, Col, Button} from "react-bootstrap"

import { Highlighter, Token, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { Link, useHistory, useParams } from "react-router-dom"

import { Context } from '../../services/Context'
import { handleError } from "../../helpers/ErrorHelpers"
import MainStructure from "../../structures/MainStructure"
import Status from "../../templates/Status"

export default function UpdateGroup(){
    const { getAxios, getAuthenticatedUser } = useContext(Context)
    const axios = getAxios()
    const history = useHistory()
    const { updateGroupId } = useParams()

    const props = {};

    const [userbusinessModel, setUserbusinessModel] = useState("")
    const [status, setStatus] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    const [groupName, setGroupName] = useState("")
    const [groupExternalId, setGroupExternalId] = useState("")
    const [partnerName, setPartnerName] = useState(false)
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [clientCompanies, setClientCompanies] = useState([])

    useEffect(() => {
        async function checkAndGetUpdateGroupData() {

            const user = await getAuthenticatedUser()

            if (user.access_level !== 'admin' || (user.business_model !== 'partner' && user.business_model !== 'gryfo')) {
                history.replace("/")
                return
            }

            if (!updateGroupId) {
                history.replace("/groups")
                return
            }

            setUserbusinessModel(user.business_model)

            let group = await getGroupData(user.business_model)

            setGroupName(group.name)
            setGroupExternalId(group.external_id)

            if(user.business_model === 'gryfo'){
                let partner = await getPartner(group.partner_id)
                if(partner){
                    setPartnerName(partner.company_name)
                }
            }

            let clientCompanies = await getClientCompanies(group.partner_id)
            if (!clientCompanies) history.replace("/groups")
            setClientCompanies(clientCompanies)
            setSelectedCompanies(getSelectedCompanies(clientCompanies, group, user.business_model))

        }

        checkAndGetUpdateGroupData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function getPartner(partnerId){
        let partner = false
        try {
            let response = await axios.get(`/company/${partnerId}/storage`)
            if (response && response.data){
                partner = response.data.company
            }
        }catch (error) {
            handleError(error, "UpdateGroup", "getPartner", partner)
        }
        return partner
    }

    async function getClientCompanies(partnerId) {

        let companies = false
        try {
            let response = await axios.get('/companies')
            if (response && response.data) {
                companies = []
                for (var company in response.data.companies) {
                    if(response.data.companies[company].business_model === 'client' && response.data.companies[company].partner_id === partnerId ){
                        companies.push({
                            _id: response.data.companies[company]._id,
                            businessModel: response.data.companies[company].business_model,
                            name: response.data.companies[company].company_name,
                            partnerId: response.data.companies[company].partner_id,
                            externalId: response.data.companies[company].external_id,
                        })
                    }
                        

                }
            }
        } catch (error) {
            handleError(error, "UpdateGroup", "getClientCompanies", companies)
        }
        return companies
    }

    async function getGroupData(userbusinessModel) {
        let group = false
        let url = `/company_group/${updateGroupId}`
        try {

            if (userbusinessModel === 'gryfo') {
                url = `/company_group/${updateGroupId}/storage`
            }

            let response = await axios.get(url)

            if (response && response.data) {
                group = response.data.company_group
            }
        } catch (error) {
            handleError(error, "UpdateGroup", "getGroupData", { updateGroupId: updateGroupId })
        }
        return group
    }

    function getSelectedCompanies(clientCompanies, group, userbusinessModel){

        let selectedIds = group.companies_list
        let selectedCompanies;
        if(userbusinessModel === 'gryfo'){
            selectedCompanies = clientCompanies.filter(company => 
                selectedIds.includes(company._id))
        }
        else{
            selectedCompanies = clientCompanies.filter(company => 
                selectedIds.includes(company.externalId))
        }
        return selectedCompanies
    }

    function checkFormIsValid() {
        if (!groupName) return false
        return true
    }

    async function postGroup() {
        setIsLoading(true)
        let status = { success: false, message: "Não foi possível atualizar o Grupo" }
        let url = `/company_group/update/${updateGroupId}`
        let companies_ids
        try {
            if(userbusinessModel === 'gryfo'){
                companies_ids = selectedCompanies.map((company) => { 
                    return company["_id"]
                })
                url = `/company_group/update/${updateGroupId}/storage`
            }else{
                companies_ids = selectedCompanies.map((company) => { 
                    return company["externalId"]
                })
            }
            
            let data = {
                name: groupName,
                companies_list:companies_ids
            }
            if (groupExternalId !== ''){
                data['external_id'] = groupExternalId
            }

            let response = await axios.post(url, data)
            if (response) {
                status = response.data
            }
        } catch (error) {
            handleError(error, "AddCompany", "postGroup", groupName)
        }
        setIsLoading(false)
        return status
    }

    async function handleSubmit(e) {
        e.preventDefault()
        if (!checkFormIsValid()) {
            return
        }
        let status = await postGroup()
        if (status.success) {
            status.message = "Grupo atualizado com sucesso!"
        }

        setStatus(status)
    }

    props.renderMenuItemChildren = (option, { text }) => (
        <Fragment>
          <Highlighter search={text}>
            {option.name}
          </Highlighter>
          <div>
            <small>
              External ID: {option.externalId}
            </small>
          </div>
        </Fragment>
    );

    props.renderToken = (option, { onRemove }, index) => (
    <Token
        key={index}
        onRemove={onRemove}
        option={option}>
        {`${option.name} (ID: ${option.externalId})`}
    </Token>
    );
    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">ATUALIZAR UM GRUPO DE EMPRESAS</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/groups">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-arrow-left mr-1" /> Voltar
                            </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Status status={status} />
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>Nome do Grupo</Form.Label>
                                <Form.Control type="text" value={groupName} onChange={e => setGroupName(e.target.value)} placeholder="Insira o nome do grupo de empresas" required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>ID Externo (opcional)</Form.Label>
                                <Form.Control type="text" value={groupExternalId} onChange={e => setGroupExternalId(e.target.value)} placeholder="Insira o ID do grupo, gerado automaticamente caso não informado"/>
                            </Form.Group>
                            <Form.Group hidden={userbusinessModel === 'partner'}>
                                <Form.Label>Empresa Parceira</Form.Label>
                                <Form.Control disabled type="text" value={partnerName}>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group >
                                <Form.Label>Empresas Inclusas</Form.Label>
                                <Typeahead
                                    {...props}
                                    id="basic-typeahead-multiple"
                                    labelKey="name"
                                    multiple
                                    onChange={setSelectedCompanies}
                                    options={clientCompanies}
                                    placeholder="Empresas a Serem Incluidas no Grupo..."
                                    selected={selectedCompanies}
                                    required
                                />
                            </Form.Group>
                            {isLoading ?
                                <Button className="btn btn-gryfo-blue" disabled type="button">
                                    <i className="fas fa-spin fa-spinner" />&nbsp;
                                Carregando
                                </Button>
                                :
                                <Button className="btn btn-gryfo-blue" type="submit">Atualizar</Button>
                            }
                        </Col>
                    </Form.Row>
                </Form>
            </div>
        </MainStructure>
    )
}