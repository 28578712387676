import * as Sentry from "@sentry/react"

export async function handleError(error, page = "unknown", method = "unknown", requestData = {}, redirect = true) {
    Sentry.withScope(scope => {
        scope.setExtra("Página", page)
        scope.setExtra("Função", method)
        scope.setExtra("Data da Requisição", requestData)
        Sentry.captureException(error)
    })

    if (redirect && process.env.NODE_ENV !== "development") {
        if (error && error.response && error.response.status === 500) {
            window.location.replace("/error/500")
        } else {
            window.location.replace("/error")
        }
    } else {
        console.log(error)
    }
}