import React, { useState, useEffect, useContext, Fragment } from "react"
import { Highlighter, Token, Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { Context } from '../services/Context'
import { handleError } from "../helpers/ErrorHelpers"


export default function PersonFilter({
    onSelectPerson,
    labelKey = "external_id",
    companyClient = null,
    placeholder = null
}) {

    const { getAxios } = useContext(Context)
    const axios = getAxios()
    const props = {};

    const [persons, setPersons] = useState([])
    const [selectedPerson, setSelectedPerson] = useState([])

    useEffect(() => {
        loadPersons()
        setSelectedPerson([])
        onSelectPerson(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyClient])

    async function loadPersons() {
        let localPersons = await getPersons()
        if (localPersons) setPersons(localPersons)
        setSelectedPerson([])

    }

    async function getPersons() {
        if (!companyClient) return
        let persons = false
        try {
            let response = await axios.get(`/persons/company/${companyClient['_id']}/storage`)
            if (response && response.data.success) {
                persons = response.data.persons
            } else {
                persons = []
            }
        } catch (error) {
            handleError(error, "Audit", "getPersons")
        }
        return persons
    }

    async function handleChangePerson(person) {
        setSelectedPerson(person)
        onSelectPerson(person[0])
    }

    props.renderMenuItemChildren = (option, { text }) => (
        <Fragment>
          <Highlighter search={text}>
            {option.name}
          </Highlighter>
          <div>
            <small>
              External ID: {option.external_id}
            </small>
          </div>
        </Fragment>
    );

    props.renderToken = (option, { onRemove }, index) => (
        <Token
            key={index}
            onRemove={onRemove}
            option={option}>
            {`${option.name} (ID: ${option.external_id})`}
        </Token>
    );

    return (

        <div className="form-group my-2 col-12 col-md align-self-center">
            <Typeahead
                {...props}
                id="basic-typeahead-single"
                clearButton
                labelKey={labelKey}
                filterBy={['name', 'external_id']}
                onChange={handleChangePerson}
                options={persons}
                placeholder={placeholder ? placeholder : "Pessoa"}
                selected={selectedPerson}
            />
        </div>
    )
}
