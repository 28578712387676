import React, { useContext, useState } from "react"

import { Modal, Button } from "react-bootstrap"
import { Context } from '../../services/Context'


export default function ModalCompanyActivation({ companyActivationArrayData, setCompanyActivationArrayData, getAndSetCompanies, activate }) {

    const { getAxios } = useContext(Context)
    const axios = getAxios()
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")


    async function updateCompanyActivation() {
        if (!companyActivationArrayData || companyActivationArrayData.length === 0) return
        setIsLoading(true)
        let response = await axios.delete(`/company/${activate ? "activate" : "deactivate"}/${companyActivationArrayData[1]}/storage`)
        if (response && response.data && response.data.message) {
            setMessage(response.data.message)
        }
        getAndSetCompanies()
        setIsLoading(false)
    }

    function closeModal() {
        setMessage("")
        setIsLoading(false)
        setCompanyActivationArrayData(null)
    }

    return companyActivationArrayData &&
        <Modal
            show={companyActivationArrayData != null}
            onHide={() => closeModal()}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton={!isLoading}>
                <Modal.Title id="contained-modal-title-vcenter">
                    Você deseja {activate ? "ativar" : "desativar"} a empresa {companyActivationArrayData[0]}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {message ?
                    message :
                    <p> Clique em {activate ? "Ativar" : "Desativar"} para confirmar</p>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-gryfo-blue" disabled={isLoading} onClick={() => closeModal()}>Sair</Button>
                {!message &&

                    <Button className={activate ? "btn btn-success bg-gryfo-green" : "btn btn-danger"} disabled={isLoading} onClick={() => updateCompanyActivation()}>{activate ? "Ativar" : "Desativar"}</Button>
                }
            </Modal.Footer>
        </Modal>


}