import React, { useState, useEffect, useContext } from "react"

import { Context } from '../services/Context'

export default function BusinessModelFilter({ onSelectBusinessModel }) {

    const { getAuthenticatedUser } = useContext(Context)
    const [companies, setCompanies] = useState([])
    const [company, setCompany] = useState("")

    useEffect(() => {
        loadCompanies()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function loadCompanies() {
        let user = await getAuthenticatedUser()
        if (user && user.business_model === 'gryfo'){
            setCompanies([{
                key: "client",
                value: "Cliente"
            }, {
                key: "partner",
                value: "Parceiro"
            },])            
        }
    }

    async function handleChangeCompany(e) {
        setCompany(e.target.value)
        onSelectBusinessModel(e.target.value)
    }

    return (

        companies && companies.length > 0 ?
            <div className="form-group my-2 col-12 col-md">
                <select className="form-control" onChange={handleChangeCompany} value={company}>
                    <option value={''}>Tipo do Modelo de negócio</option>
                    {(companies && companies.length > 0) && companies.map((company, index) =>
                        <option value={company.key} key={index + company.key}>{company.value}</option>
                    )}
                </select>
            </div>
        :''
    )
}
