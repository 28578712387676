import React, { useState, useEffect, useContext } from 'react'

import { Row, Col, Form, Button } from "react-bootstrap"
import moment from 'moment'
import { Context } from '../../services/Context'
import MainStructure from "../../structures/MainStructure"
import Loader from '../../templates/Loader'
import AuditionDashboard from './AuditionDashboard'
import Paginator from './Paginator'
import { handleError } from "../../helpers/ErrorHelpers"
import CompaniesFilter from "../../templates/CompaniesFilter"
import PersonFilter from "../../templates/PersonFilter"
import TooltipTemplate from '../../templates/TooltipTemplate'
import ModalExportAudits from './ModalExportAudits'
import DateFilter from "../../templates/DateFIlter"



export default function Audit() {
    const { getAxios, getAuthenticatedUser } = useContext(Context)
    const axios = getAxios()

    const [lastPage, setLastPage] = useState(false)
    const [auditions, setAuditions] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(6)
    const [selectedCategory, setSelectedCategory] = useState("all")
    const [selectedPerson, setSelectedPerson] = useState(false)
    const [companyPartner, setCompanyPartner] = useState(false)
    const [companyClient, setCompanyClient] = useState(false)
    const [filter, setFilter] = useState(false)
    const [user, setUser] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState(moment().endOf('day').toDate())

    useEffect(() => {
        getAndSetUserBusinessModel()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getRecentAuditions(filter)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [limit, page])

    async function getAndSetUserBusinessModel() {
        const user = await getAuthenticatedUser()
        if (user) {
            setUser(user)
        }
    }

    async function getRecentAuditions(filter) {
        let auditions = false
        let lastPage = false
        try {
            console.log(filter)
            let response = await axios.post(`/auditions/${page}/${limit}`, filter)
            if (response && response.status === 200) {
                auditions = response.data.auditions
                lastPage = response.data.totalPages
            }

        } catch (error) {
            handleError(error, "Audit", "getRecentAuditions", { filter: filter })
        }

        if (auditions) setAuditions(auditions)
        setLastPage(lastPage)
    }

    function handlePagination(page, limit) {
        setPage(page)
        setLimit(limit)
    }

    function handleSearch(e) {
        e.preventDefault()
        setPage(1)
        if(!companyClient) return;
        const filter = {
            category: selectedCategory
        }
        if(user.business_model === 'gryfo'){
            filter['company_id'] = companyClient['_id']
        }else{
            filter['company_external_id'] = companyClient['external_id']
        }

        if (selectedPerson) filter['external_id'] = selectedPerson['external_id']
        if (startDate) filter['start_date'] = Math.floor(startDate.getTime() / 1000)
        if (endDate) filter['end_date'] = Math.floor(endDate.getTime() / 1000)
        setFilter(filter)
        getRecentAuditions(filter)
    }

    async function handleAuditAction(actionType, auditId) {
        const url = `/auditions/${actionType === "discard" ? "discard" : "report"}/${auditId}`
        try {
            let response = await axios.put(url)
            if (response && response.data.success) {
                removeAuditFromList(auditId)
            } else if (response && response.data.message) {
                window.alert(response.data.message)
            } else {
                window.alert("Não foi possível remover esse audit")
            }

        } catch (error) {
            handleError(error, "Audit", "handleAuditAction", { audit_id: auditId })
        }
    }

    function removeAuditFromList(auditId) {
        let newAuditions = [...auditions]
        newAuditions = newAuditions.filter((audit) => audit._id !== auditId)
        setAuditions(newAuditions)
    }

    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">AUDITORIA</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <TooltipTemplate legend="Exportar para CSV">
                            <Button className="btn bg-gryfo-blue d-flex align-items-center mr-3 py-2" onClick={() => setIsModalVisible(true)}>
                                <i className="fas fa-file-csv" style={{ fontSize: "14pt" }} />
                            </Button>
                        </TooltipTemplate>
                        <Paginator actual={page} last={lastPage} entries={limit} update={handlePagination} />
                    </Col>
                </Row>
                <hr />
                {user ?
                    <>
                        <Form onSubmit={handleSearch}>
                            <Form.Row>
                                <Form.Group as={Col} xs={12} md className="my-2 align-self-center">
                                    <DateFilter
                                        startDate={startDate}
                                        setStartDate={setStartDate}
                                        endDate={endDate}
                                        label="Início"
                                    />
                                </Form.Group>
                                <Form.Group as={Col} xs={12} md className="my-2 align-self-center">
                                    <DateFilter
                                        startDate={endDate}
                                        setStartDate={setEndDate}
                                        label="Fim"
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} xs={12} md className="my-2 align-self-center">
                                    <Form.Control as="select" onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory} required={true}>
                                        <option value={'all'} key={'default-category-select'}>Todas Categorias</option>
                                        <option value={'FRAUD_EVIDENCE'} key={'fraud-evidence-category-select'}>Indicio de Fraude</option>
                                    </Form.Control>
                                </Form.Group>
                                {(user && user.business_model === "gryfo") &&
                                    <CompaniesFilter
                                        className="form-group my-2 col-12 col-md align-self-center"
                                        onSelectCompany={setCompanyPartner}
                                        companyMode="partner"
                                        placeholder="Parceiro"
                                        required={true}
                                    />}
                                {(user && user.business_model !== 'client') &&
                                    <CompaniesFilter    
                                    className="form-group my-2 col-12 col-md align-self-center"
                                        onSelectCompany={setCompanyClient}
                                        companyMode="client"
                                        partnerId={
                                            (companyPartner) ?
                                            companyPartner['_id']
                                            :
                                            null
                                        }
                                        placeholder="Cliente"
                                        disabled={!companyPartner}
                                        required={true}
                                    />}
                                <PersonFilter 
                                    onSelectPerson={setSelectedPerson}
                                    labelKey={"external_id"}
                                    companyClient={companyClient}
                                
                                />
                                <Button variant="primary" className="btn btn-gryfo-blue my-2" type="submit" style={{ width: "125px" }}>
                                    <i className="fab fa-searchengin mr-2" style={{ fontSize: "14pt" }} />Pesquisar
                                </Button>
                            </Form.Row>
                        </Form>
                        {filter ?
                            <Loader show={auditions}>
                                {auditions.length > 0 ? <AuditionDashboard auditions={auditions} handleAuditAction={handleAuditAction} /> : 'Não foram encontrados resultados'}
                            </Loader>
                            :
                            <p>Selecione o filtro para exibir as auditorias</p>
                        }
                    </>
                    :
                    <Loader show={false}></Loader>
                }
            </div>
            {user &&
                <ModalExportAudits isVisible={isModalVisible} setIsVisible={setIsModalVisible} user={user} />
            }
        </MainStructure>
    )
}

