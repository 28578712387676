import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'


import Sidebar from "./Sidebar"
import NavBar from "./Navbar"

export default function MainStructure({ children, ...props }) {

    const [width, setWidth] = useState(window.innerWidth)
    const [isMounted, setIsMounted] = useState(false)

    const breakpoint = 767

    useEffect(() => {
        setIsMounted(true)
        window.addEventListener("resize", () => {
            if (isMounted) setWidth(window.innerWidth)
        })
        return () => {
            setIsMounted(false)
        }
    }, [isMounted])
    return (
        <div className="main_container">
            <NavBar width={width} breakpoint={breakpoint} />
            <Container fluid className="flex-grow-1">
                <Row className="h-100-percentage">
                    {width > breakpoint && <Sidebar />}
                    <Col className="static_content_main">
                        <Row>
                            <Col>
                                {children}
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>
        </div>

    )
}