import React, { useState, useEffect, useContext } from "react"
import $ from 'jquery'
import { Form, Row, Col, Button, InputGroup, FormControl } from "react-bootstrap"


import { Link, useHistory } from "react-router-dom"
import datatablePt from "../../../assets/lang/datatablePt.json"

import Loader from '../../templates/Loader'
import { Context } from '../../services/Context'
import { handleError } from "../../helpers/ErrorHelpers"
import CompaniesFilter from "../../templates/CompaniesFilter"
import MainStructure from "../../structures/MainStructure"

$.DataTable = require("datatables.net")


export default function GroupList(){

    const { getAxios, getAuthenticatedUser } = useContext(Context)
    const axios = getAxios()
    const history = useHistory()

    const [userbusinessModel, setUserbusinessModel] = useState("")
    const [groups, setGroups] = useState(false)
    const [selectedCompany, setSelectedCompany] = useState("")

    useEffect(() => {

        async function getAndSetUser(){
            const user = await getAuthenticatedUser()
            if (user.access_level !== 'admin' || (user.business_model !== 'partner' && user.business_model !== 'gryfo')) {
                history.replace("/")
                return
            }

            setUserbusinessModel(user.business_model)

            loadCompaniesGroups()
        }
        
        getAndSetUser()
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        loadCompaniesGroups()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCompany])

    useEffect(() => {
        loadDataTable()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups])

    async function loadCompaniesGroups(e = null) {
        if (e) e.preventDefault()
        let groups = await getCompaniesGroups()
        setGroups(groups)
    }

    async function getCompaniesGroups(){
        
        let groups = false
        let response = false
        try {
            if(selectedCompany){
                response = await axios.post('/company_groups', {
                    'partner_id': selectedCompany['_id']
                })
            }else{
                response = await axios.get('/company_groups')
            }
            
            if (response && response.data) {
                groups = response.data.companies_groups
            }
        } catch (error) {
            handleError(error, "GroupList", "getCompaniesGroups")
        }
        return groups

    }

    function clearDataTable() {
        $('#groups-table').DataTable().clear()
        $('#groups-table').DataTable().destroy()
    }

    function loadDataTable() {
        if ($('#companies-table').DataTable()) {
            clearDataTable()
        }
        let data = []
        if (groups && groups.length > 0) {
            groups.forEach(item => {
                data.push([item.name, item.companies_list, item._id, item.external_id])
            })
            
            var table = $('#groups-table').DataTable({
                dom: 'Bitp',
                "language": datatablePt,
                data: data,
                "columnDefs": [
                    {
                        "data": null,
                        "render": function (data, type, row, meta) {
                            let groupName = row[0]
                            return `<div class="text-center" style="font-size: 10pt;">
                                        <i id="edit-btn-`+ groupName + `" name="edit-btn" class="m-1 h4 text-gryfo-dark-yellow cursor-pointer fas fa-edit" data-toggle="tooltip" data-placement="left" title="Editar Grupo"></i>`
                                +
                                `<i id="rm-btn-` + groupName + `" name="rm-btn" class="m-1 h4 text-danger cursor-pointer fas fa-trash" data-toggle="tooltip" data-placement="left" title="Remover Grupo"></i>
                                    </div>`
                        },
                        "targets": [-1]
                    },
                    {   
                        "targets": [1],
                        "data": null,
                        "render": function (data, type, row, meta) {
                            let companieslist = row[1]
                            let companies = ''
                            companieslist.forEach(company => {
                                companies+=`<li>${company.name}</li>`
                            })
                            return companies          
                        }
                    }
                ]
            })

            $('#search-groups').on('keyup', function () {
                table.search($('#search-groups').val()).draw();
            });

            $('body').on('click', "i[name='edit-btn']", function () {
                var data = table.row($(this).parents('tr')).data();
                if (!data || data.length === 0) return

                if(userbusinessModel === 'gryfo'){
                    history.push('/group/update/' + data[2])
                }else{
                    history.push('/group/update/' + data[3])
                }
            });

            $('body').on('click', "i[name='rm-btn']", async function () {
                var data = table.row($(this).parents('tr')).data();
                if (!data || data.length === 0) return

                if (!window.confirm("Deseja mesmo remover este grupo?")) return
                let response;
                if(userbusinessModel === 'gryfo'){
                    response = await axios.delete('/company_group/delete/' + data[2] + '/storage')
                }else{
                    response = await axios.delete('/company_group/delete/' + data[3])
                }
                if (response && response.status === 200) {
                    response.data.success ? loadCompaniesGroups() : alert(response.data.message)
                }
            });
        }
    }

    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
            <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">GRUPO DE EMPRESAS</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/group/add">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-plus mr-1" /> Cadastrar Grupo de Empresas
                        </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <Loader show={groups}>
                    <Row>
                        <Col>
                            <Form>
                                <Form.Row>
                                    <InputGroup className="form-group my-2 col-12 col-md">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text ><i className="fas fa-search"></i></InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl id="search-groups" placeholder="Procurar Grupo de Empresas" />
                                    </InputGroup>
                                    {userbusinessModel === 'gryfo' ?
                                        <CompaniesFilter
                                            className="form-group my-2 col-12 col-md align-self-center"
                                            onSelectCompany={setSelectedCompany} 
                                            companyMode={'partner'}
                                            placeholder={'Parceiro'} 
                                        />
                                    : ''}
                                    
                                </Form.Row>
                            </Form>
                        </Col>
                    </Row>
                    {(groups && groups.length > 0) ?
                        <div className="table-responsive">
                            <table id="groups-table" className="table table-striped table-bordered" width="100%">
                                <thead className="bg-gryfo-blue text-white">
                                    <tr>
                                        <th>NOME</th>
                                        <th>EMPRESAS</th>
                                        <th className="text-center" style={{ maxWidth: "300px" }}>AÇÕES</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        :
                        'Não há grupos a serem exibidos'
                    }
                </Loader>
            </div>
        </MainStructure>
    )
}