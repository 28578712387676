import React, { useState, useEffect } from "react"

import axios from "axios"

import { useHistory } from "react-router-dom"

import { setStorages, getStorageToken, getStorageRefreshToken, resetUserStorages } from "../helpers/StorageHelpers"
import { handleError } from "../helpers/ErrorHelpers"

export const Context = React.createContext({})

export default function Auth({ children }) {

    const API_VERSION = "1.0";

    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [token, setToken] = useState(false)
    const [user, setUser] = useState(false)
    const [category, setCategory] = useState(false)
    let history = useHistory()

    useEffect(() => {
        checkAuth()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function checkAuth() {
        checkIsAuthenticated()
            .then((authenticated) => setIsAuthenticated(authenticated))
            .then(() => setIsLoading(false))
    }

    async function checkIsAuthenticated() {
        if (!user) {
            let authenticatedUser = await getAuthenticatedUser()
            if (authenticatedUser) {
                setUser(authenticatedUser)
                setCategory(authenticatedUser.category)
            } else {
                setUser(false)
            }
            return authenticatedUser ? true : false
        } else {
            return true
        }
    }

    async function getAuthenticatedUser() {
        if (!user) {
            let authenticatedUser = await loadAuthenticatedUser()
            return authenticatedUser
        } else {
            return user
        }
    }

    async function loadAuthenticatedUser() {
        let authenticatedUser = false
        let axios = getAxios()
        try {
            let response = await axios.get("/user")
            if (response && response.data.success) {
                authenticatedUser = response.data.user
            }
        } catch (error) {
            handleError(error, "Context", "loadAuthenticatedUser", {}, false)
        }
        return authenticatedUser
    }


    async function doLogin(username, password) {
        setIsLoading(true)
        let response = await postLogin(username, password)
        if (response && response.success && response.accessToken) {
            setStorages({ token: response.accessToken, refreshToken: response.refreshToken })
            setToken(response.accessToken)
            setIsAuthenticated(true)
        } else if (!response) {
            response = { success: false, message: "Não foi possível fazer login, tente novamente mais tarde." }
        }
        setIsLoading(false)
        return response
    }

    async function postLogin(username, password) {
        let response = false
        let axios = getAxios()
        try {
            response = await axios.post("/frontend/login", { username: username, password: password })
            response = response ? response.data : false
        } catch (error) {
            handleError(error, "Context", "postLogin", { username: username }, false)
        }
        return response
    }

    function getToken() {
        return token ? token : getStorageToken()
    }

    function getBaseUrl() {
        let baseURL = ''
        switch (process.env.NODE_ENV) {
            case "development":
                baseURL = process.env.REACT_APP_API_BASE_URL_DEV
                break
            default:
                baseURL = "https://" + window.location.hostname + "/api"
                break
        }
        return baseURL
    }

    function getAxios() {
        let baseURL = getBaseUrl()     
        const token = getToken()

        let options = {}
        if (baseURL) options["baseURL"] = baseURL
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
            axios.defaults.headers.common["X-Client-Version"] = API_VERSION
        }
        let axiosInstance = axios.create(options)

        axiosInstance.interceptors.response.use(response => {
            return response;
        }, function (error) {
            if (error.response.status === 401) {
                return new Promise((resolve, reject) => {
                    const originalReq = error.config;
                    let response = fetch(baseURL + '/token', {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        redirect: 'follow',
                        referrer: 'no-referrer',
                        body: JSON.stringify({
                            token: `${getStorageRefreshToken()}`
                        }),
                    }).then(async function (response) {
                        if (response.status === 200) {
                            response = await response.json()
                            setStorages({ token: response.accessToken, refreshToken: response.refreshToken })
                            setToken(response.accessToken)
                            originalReq.headers['Authorization'] = `Bearer ${response.accessToken}`;
                            return axiosInstance(originalReq);
                        } else {
                            response = await response.json()
                            setIsAuthenticated(false)
                            setUser(false)
                            setCategory(false)
                            history.push({
                                pathname: "/login",
                                state: { message: response.message }
                            })
                            return reject(response)
                        }
                    })

                    resolve(response);
                });
            }
            return Promise.reject(error)
        });

        return axiosInstance
    }

    async function postLogout(refreshToken) {
        let response = false
        let axios = getAxios()
        try {
            response = await axios.post("/logout", { token: `${refreshToken}` })
            response = response ? response.data : false
        } catch (error) {
            handleError(error, "Context", "postLogout", { token: refreshToken }, false)
        }
        return response
    }

    async function doLogout() {
        let refreshToken = getStorageRefreshToken()
        postLogout(refreshToken)
        resetStates()
    }

    function resetStates() {
        resetUserStorages()
        setUser(false)
        setCategory(false)
        setToken(false)
        setIsAuthenticated(false)
    }


    return (
        <Context.Provider value={{
            isAuthenticated,
            isLoading,
            getToken,
            getAxios,
            getAuthenticatedUser,
            category,
            setCategory,
            doLogin,
            doLogout,
            resetStates,
            getBaseUrl
        }}>
            {children}
        </Context.Provider>
    )
}

