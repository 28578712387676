import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function Loader({ children, ...props }) {
    const { show } = props
    return (
        <div>
            { show ?
                children
                :
                <Spinner animation="border" role="status" variant="gryfo-blue">
                    <span className="sr-only">Carregando...</span>
                </Spinner>
            }
        </div>
    )
}
