import React, { useState } from "react"
import { Tooltip, OverlayTrigger } from "react-bootstrap"

export default function TooltipTemplate({ children, ...props }) {

    const [legend] = useState(props.legend || false)

    function renderTooltip(props) {
        return (
            legend &&
            <Tooltip id="button-tooltip" {...props}>
                {legend}
            </Tooltip>

        )
    }

    return (
        <OverlayTrigger placement={props.replacement || "bottom"} overlay={renderTooltip}>{children}</OverlayTrigger>
    )
}
