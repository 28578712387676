import React from "react"

import { Container, Row, Col, Image } from 'react-bootstrap'
import whiteLogo from "../../assets/images/logo-gryfo-branco.svg"

export default function AuthenticationStructure({ children, ...props }) {
    return (
        <div className="main_container">
            <Container fluid className="flex-grow-1">
                <Row className="h-100-percentage">
                    <Col xs={11} md={4} className="auth_container py-4 mx-auto">
                        <Row>
                            <Col className="mb-3" xs={12}>
                                <Image src={whiteLogo} width={200} />
                            </Col>
                        </Row>
                        {children}
                    </Col>
                </Row>
            </Container>
        </div>

    )
}