import React, { useState } from "react"

export default function IsActiveFilter({ onChange }) {

    const [isActive, setIsActive] = useState(1)

    async function handleChange(e) {
        setIsActive(Number(e.target.value))
        onChange(Boolean(Number(e.target.value)))
    }

    return (

        <div className="form-group my-2 col-12 col-md">
            <select className="form-control" onChange={handleChange} value={isActive}>
                <option value={1}>Ativos</option>
                <option value={0}>Inativos</option>
            </select>
        </div>
    )
}
