import React, { useState, useContext, useEffect } from "react"

import { Row, Col, Form, Button } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"

import { Context } from '../../services/Context'
import { handleError } from "../../helpers/ErrorHelpers"
import MainStructure from "../../structures/MainStructure"
import Status from "../../templates/Status"

export default function AddCompany() {
    const { getAxios, getAuthenticatedUser } = useContext(Context)
    const axios = getAxios()
    const history = useHistory()

    const [companyName, setCompanyName] = useState("")
    const [companyExternalId, setCompanyId] = useState("")
    const [businessModel, setbusinessModel] = useState("partner")
    const [userbusinessModel, setUserbusinessModel] = useState("")
    const [partnerId, setPartnerId] = useState("")
    const [companies, setCompanies] = useState([])
    const [status, setStatus] = useState(false)
    const [gryfoWhiteLabel, setGryfoWhiteLabel] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        async function getAndSetCompanies() {
            const user = await getAuthenticatedUser()
            if (user.access_level !== 'admin' || (user.business_model !== 'partner' && user.business_model !== 'gryfo')) {
                history.replace("/")
                return
            }

            let companies = await getCompanies()
            if (!companies) history.replace("/companies")
            setCompanies(companies)

            setUserbusinessModel(user.business_model)
            if (user.business_model === 'partner') {
                setbusinessModel('client')
                setPartnerId(user._id)
            }

        }
        getAndSetCompanies()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleCheck(e){
        setGryfoWhiteLabel(e.target.checked)
    }

    async function getCompanies() {

        let companies = false
        try {
            let response = await axios.get('/companies')
            if (response && response.data) {
                companies = []
                for (var company in response.data.companies) {
                    if (response.data.companies[company].partner_id === null) {
                        companies.push({
                            _id: response.data.companies[company]._id,
                            name: response.data.companies[company].company_name,
                            partnerId: response.data.companies[company].partner_id,
                        })
                    }
                }
            }
        } catch (error) {
            handleError(error, "AddCompany", "getCompanies", companies)
        }
        return companies
    }

    useEffect(() => {
        if (status) setStatus(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyName, businessModel, partnerId])


    function checkFormIsValid() {
        if (!companyName || !businessModel) return false
        if (businessModel === 'client' && !partnerId) return false

        return true
    }

    async function handleSubmit(e) {
        e.preventDefault()

        if (!checkFormIsValid()) return
        let status = await postCompany()
        if (status.success) {
            status.message = "Empresa criada com sucesso!"
            setCompanyName("")
            setbusinessModel("partner")
            setPartnerId("")
        }

        setStatus(status)
    }

    async function postCompany() {
        setIsLoading(true)
        let status = { success: false, message: "Não foi possível criar a Empresa" }

        try {
            let data = {
                company_name: companyName,
                business_model: businessModel,
                gryfo_white_label: gryfoWhiteLabel
            }
            if (companyExternalId !== ''){
                data['external_id'] = companyExternalId
            }
            if (businessModel === 'client') {
                data['partner_id'] = partnerId
            }
            let response = await axios.post("/company/add", data)
            if (response && response.data.success) {
                status = response.data
            }
        } catch (error) {
            handleError(error, "AddCompany", "postCompany", companyName)
        }
        setIsLoading(false)
        return status
    }

    return (
        <MainStructure>
            <div className="mt-4 mx-sm-4">
                <Row className="mb-2">
                    <Col xs={12} sm>
                        <h2 className="text-gryfo-dark-blue mb-0 font-weight-bold">ADICIONAR UMA EMPRESA</h2>
                    </Col>
                    <Col xs={12} sm className="d-sm-flex py-3 py-sm-0 justify-content-end align-items-center">
                        <Link to="/companies">
                            <Button className="btn btn-gryfo-blue" >
                                <i className="fas fa-arrow-left mr-1" /> Voltar
                        </Button>
                        </Link>
                    </Col>
                </Row>
                <hr />
                <Form onSubmit={handleSubmit}>
                    <Form.Row>
                        <Status status={status} />
                        <Col xs={12}>
                            <Form.Group>
                                <Form.Label>Nome da Empresa</Form.Label>
                                <Form.Control type="text" value={companyName} onChange={e => setCompanyName(e.target.value)} placeholder="Insira o nome da empresa" required />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>ID Externo (opcional)</Form.Label>
                                <Form.Control type="text" value={companyExternalId} onChange={e => setCompanyId(e.target.value)} placeholder="Insira o id da empresa, gerado automaticamente caso não informado"/>
                            </Form.Group>
                            <Form.Group>
                                {userbusinessModel === 'gryfo' ?    
                                <>
                                    <Form.Label>Modelo de Negócio</Form.Label>
                                    <Form.Control as="select" value={businessModel} onChange={e => setbusinessModel(e.target.value)}>
                                        <option value="partner">Parceiro</option>
                                        <option value="client">Cliente</option>
                                    </Form.Control>
                                </>
                                :<Form.Control type="text" hidden disabled value={''} placeholder={'Cliente'}/>}
                            </Form.Group>
                            <Form.Group hidden={businessModel === 'partner' || userbusinessModel === 'partner'}>
                                <Form.Label>Empresa Parceira</Form.Label>
                                <Form.Control custom as="select" value={partnerId} onChange={e => setPartnerId(e.target.value)}>
                                    <option disabled value={''} key={'default-module-select'}>Selecione a Empresa</option>
                                    {(companies && companies.length > 0) && companies.map(company =>
                                        <option key={company._id} value={company._id}> {company.name} </option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                            {businessModel === 'partner' && userbusinessModel === 'gryfo' ?
                            <>
                            <Form.Label>Gryfo White Label</Form.Label>
                                <Form.Check type="checkbox" value={gryfoWhiteLabel} onChange={handleCheck} disabled={status.success} />
                            </>: <></>}
                            
                            <hr />
                            {isLoading ?
                                <Button className="btn btn-gryfo-blue" disabled type="button">
                                    <i className="fas fa-spin fa-spinner" />&nbsp;
                                Carregando
                            </Button>
                                :
                                <Button className="btn btn-gryfo-blue" type="submit">Cadastrar</Button>
                            }
                        </Col>
                    </Form.Row>
                </Form>
            </div>
        </MainStructure>
    )
}